export const timePreferences = [

  { name: "16-05-2024 08:45 (DD-MM-YYYY HH:MM)", value: "11" },
  { name: "16/05/2024 08:45 (DD/MM/YYYY HH:MM)", value: "12" },
  { name: "16 Mayıs 2024 08:45 (DD/Mon/YYYY HH:MM)", value: "13" }, 
  { name: "16-05-24 08:45 (DD-MM-YY HH:MM)", value: "14" },
  { name: "16/05/24 08:45 (DD/MM/YY HH:MM)", value: "15" },
  { name: "16 Mayıs 24 08:45 (DD/Mon/YY HH:MM)", value: "16" }, 

  { name: "05-16-2024 08:45 (MM-DD-YYYY HH:MM)", value: "21" },
  { name: "05/16/2024 08:45 (MM/DD/YYYY HH:MM)", value: "22" },
  { name: "Mayıs 16 2024 08:45 (Mon DD YYYY HH:MM)", value: "23" }, 
  { name: "05-16-24 08:45 (MM-DD-YY HH:MM)", value: "24" },
  { name: "05/16/24 08:45 (MM/DD/YY HH:MM)", value: "25" },
  { name: "Mayıs 16 24 08:45 (Mon DD YY HH:MM)", value: "26" }, 

  { name: "2024-05-16 08:45 (YYYY-MM-DD HH:MM)", value: "31" },
  { name: "2024/05/16 08:45 (YYYY/MM/DD HH:MM)", value: "32" },
  { name: "2024 Mayıs 16 08:45 (YYYY MM DD HH:MM)", value: "33" }, 
  
  { name: "24-05-16 08:45 (YY-MM-DD HH:MM)", value: "31" },
  { name: "24/05/16 08:45 (YY/MM/DD HH:MM)", value: "32" },
  { name: "24 Mayıs 16 08:45 (YY MM DD HH:MM)", value: "33" }, 

  { name: "8 dakika önce, Dün ...", value: "41" }, 
];