import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import { languages } from '../../Constants/Languages.js';   
import { BottomMenu } from '../../Components/Menu.js';  

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.bgColor, // Set background color based on bgColor property
      color: state.isSelected ? "#888" : "#333", // Text color
      padding: 8,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#888", // Change text color of the selected value
    }),
    input: (provided) => ({
      ...provided,
      color: "#888", // Change text color while typing
    }),
  };

function ContactsMobile() {

  const navigate = useNavigate();

  const endOfPageRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showLangs, setShowLangs] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [selectedOption, setSelectedOption] = useState('');
 
 
    const toggleLangSelector = () => {
      setShowLangs(!showLangs);
    }
 
    const addContact = () => {
      
      let contactId = selectedOption?.value;

      try {
        const result = axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/add-user`,
          {friend_id:contactId},
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
      } catch (error) {}
      console.log(selectedOption);
    }
 
    const changeLanguage = (newLang) => {

      try {
        const result = axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/settings/change-default-language`,
          {lang:newLang},
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
      } catch (error) {}

      setLang(newLang);
      localStorage.setItem("lang",newLang);
 
    }
 
  let token = localStorage.getItem("token");
  const [friends, setFriends] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);

  const [activeFriend, setActiveFriend] = useState();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [showSettings, setShowSettings] = useState(false);
  const [showUserSearch, setShowUserSearch] = useState(false);
   
  const [showMenu, setShowMenu] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  const handleUserSelect = async (selectedOption) => {
    setSelectedOption(selectedOption);
  }

  const loadOptions = (inputValue, callback) => {
    // Simulate a call to a backend API which returns a promise

    axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/search-user`,
        {keyword:inputValue},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      )  
      .then(data => {
        // Transform the data into the format { value, label }
        console.log(inputValue);
        console.log(data);
        const options = data.data.data.map(item => ({
          value: item.id,
          label: item.name + " " + item.surname + " (" + getLanguageNameByCode(item.lang) + ")",
          icon: "/images/flags/"+item.lang+".png"
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        callback([]);
      });
 
  }
 
  const Option = props => {
    return (
      <components.Option {...props}>
        <img src={props.data.icon} style={{ width: 20, height: 20, marginRight: 10 }} alt="" />
        {props.data.label}
      </components.Option>
    );
  };
 
  function getLanguageNameByCode(code) {
    const language = languages.find(lang => lang.code === code);
    return language ? language.name : 'Language not found';
  }
  
  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  return (
<>
  <div className="container">
 
  <div className="column2"> 
  <div className="headerMobile">
      <div className="header">
        <b>Contacts</b>
        <div className="nav-icons" onClick={toggleLangSelector}>
          <span style={{ fontSize: "0.8em", paddingTop: 1 }}>{getLanguageNameByCode(lang)}</span>&nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
          <span style={{ width: 15, height: 15, marginTop: 5 }}>&nbsp;</span>
          <img
            src={`/images/flags/${lang}.png`}
            style={{ width: 25 }}
          />
          {showLangs && (
                <div style={{
                    display: 'flex', 
                    position:'absolute', 
                    top:'0px', 
                    left:'-100px',
                    height:'450px', 
                    width:'185px',
                    padding:'12px',
                    backgroundColor:'#333',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRadius:'10px',
                    zIndex:999
                    }}>
                    <span style={{color:'#ddd'}}>
                      {languages.map((language, index) => (
                        <table>
                          <tr
                            onClick={(e) => {
                              e.preventDefault();  // Prevent the default action of the link
                              changeLanguage(language.code);
                            }}
                          >
                            <td><img src={`/images/flags/${language.code}.png`} style={{ width: 25 }}/> </td>
                            <td valign="middle"> &nbsp; {language.name}</td>
                          </tr>
                        </table> 
                      ))}
                    </span>
                </div>
            )}
        </div> 
      </div>



      <div style={{padding:'20px'}}>
    
    <AsyncSelect
        className="form-control" 
        value={selectedOption}
        onChange={handleUserSelect}
        isSearchable={true} 
        styles={customStyles} 
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        placeholder="Write name or surname"
        components={{ Option }}
    />      
    
    <br />
    
    {selectedOption 
    ?
    <>
    
    <p align='center'>
    <img src={selectedOption.icon} style={{width:'50px'}}/> 
    <br /><br />
    Add {selectedOption.label}
     
    <br /><br />
    
    <div
      onClick={() => addContact()}
        style={{
        cursor: 'pointer',
        width:'300px', 
        height:'100px', 
        borderRadius:'10px', 
        color:'#fff', 
        backgroundColor:'#4EBD3B',
        fontSize:'18pt',
        fontWeight:'bolder',
        placeItems: 'center',
        display: 'grid' 
        }}>(+) ADD</div>
    </p>
    
    </> 
    :
    <>
    
    <p align='center'> 
    Please select contact to add your network.
    </p>
    
    </>
    }
    
    </div>
    





  </div>
  </div>
  
<BottomMenu selected="Contacts"/> 

</div>
</>
  );
 
}

export default ContactsMobile;
