import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { timePreferences } from '../../Constants/TimePreferences.js';  
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { SettingsMenu } from "../../Components/TabMenu.js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Chat() {

  const navigate = useNavigate();
  
    // State to track the selected timezone
    const [selectedTimePreference, setSelectedTimePreference] = useState();  
 
   // Handle the selection change
   const handleChange = (event) => {
    setSelectedTimePreference(event.target.value);
   };

   const getProfileInformation = async () => {
 
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-profile-info`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        console.log(result?.data?.data);
        setSelectedTimePreference(result?.data?.data?.time_preference);
       
      }
    } catch (error) {
      console.log(error)
    }
    return true;
  }

  const updateTimePreference = async () => {
 
    let reqBody = {
      time_preference:selectedTimePreference
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/update-date-preference`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.result && result?.data?.result == "Success") { 

        toast.success('👌 '+"Bilgileriniz güncellendi.", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 
    } else {
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }

    getProfileInformation();
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Settings"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Ayarlar</b>
        <div className="nav-icons">
        <LanguageSelection />
         
        </div> 
      </div>  
 
<SettingsMenu selected="DatePreferences"/>
 
<div class="form-double-container"> 
    <div className="half-width"> 
        <form className="login-form"> 
        <h4>Tarih Tercihleri</h4> 

        <div className="form-group">
                  <select value={selectedTimePreference} onChange={handleChange}>
                    {timePreferences.map((timezone, index) => (
                      <option key={index} value={timezone.value}>
                        {timezone.name}
                      </option>
                    ))}
                  </select>
                </div> 

                <span
                  style={{ cursor: "pointer", textAlign: "center" }}
                  class="btn-danger"
                  onClick={() => updateTimePreference()}
                >
                  Tarih Gösterimini Güncelle
                </span>

        </form> 
    </div> 
    <div className="half-width"> 
    <form className="login-form"> 
    <h4>Açıklamalar</h4>
             <ul className="settingDescriptions">
              <li>Yıl/Ay/Gün Saat/Dakika şeklinde tam görünüm seçebilirsiniz</li>
              <li>Biraz önce, dün vs. gibi daha kullanıcı dostu bir görünüm seçebilirsiniz.</li>
             </ul>
        </form> 
    </div> 
</div>
   
    </div> 
  </div>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

</>
  );
}
 

export default Chat;
