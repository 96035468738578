export const status = [
    { name: "----- DURUM SEÇ -----", value: "9999" },
    { name: "----- GENEL DURUMLAR -----", value: "9999" },
    { name: "Meşgul", value: "Meşgul" },
    { name: "Uygun", value: "Uygun" },
    { name: "Rahatsız Etmeyin", value: "Rahatsız Etmeyin" },
    { name: "Çevrimdışı", value: "Çevrimdışı" },
    { name: "Çevrimiçi", value: "Çevrimiçi" },
    { name: "Görünmez", value: "Görünmez" },
    { name: "Geri Dönecek", value: "Geri Dönecek" },
    { name: "Hemen Dönecek", value: "Hemen Dönecek" },
    { name: "İşte", value: "İşte" },
    { name: "Evde", value: "Evde" },
    { name: "Yolda", value: "Yolda" },
    { name: "Tatilde", value: "Tatilde" },
    { name: "Dışarıda", value: "Dışarıda" },
    { name: "Uyuyor", value: "Uyuyor" },
    { name: "Spor Yapıyor", value: "Spor Yapıyor" },
    { name: "Mola", value: "Mola" },
    { name: "Toplantıda", value: "Toplantıda" },
    { name: "Seyahatte", value: "Seyahatte" },
    { name: "Çalışıyor", value: "Çalışıyor" },
    { name: "Ders Çalışıyor", value: "Ders Çalışıyor" },
    { name: "----- RUH HALLERİ -----", value: "9999" },
    { name: "Mutlu", value: "Mutlu" },
    { name: "Üzgün", value: "Üzgün" },
    { name: "Heyecanlı", value: "Heyecanlı" },
    { name: "Kızgın", value: "Kızgın" },
    { name: "Şaşkın", value: "Şaşkın" },
    { name: "Yorgun", value: "Yorgun" },
    { name: "Stresli", value: "Stresli" },
    { name: "Rahat", value: "Rahat" },
    { name: "Motivasyonlu", value: "Motivasyonlu" },
    { name: "Sıkılmış", value: "Sıkılmış" },
    { name: "Endişeli", value: "Endişeli" },
    { name: "Huzurlu", value: "Huzurlu" },
    { name: "Romantik", value: "Romantik" },
    { name: "Sinirli", value: "Sinirli" },
    { name: "Enerjik", value: "Enerjik" },
    { name: "Düşünceli", value: "Düşünceli" },
    { name: "Melankolik", value: "Melankolik" },
    { name: "Keyifli", value: "Keyifli" },
    { name: "İlgisiz", value: "İlgisiz" },
    { name: "Şaşkın", value: "Şaşkın" },
    { name: "----- AKTİVİTE DURUMLARI -----", value: "9999" },
    { name: "Yemekte", value: "Yemekte" },
    { name: "Film İzliyor", value: "Film İzliyor" },
    { name: "Müzik Dinliyor", value: "Müzik Dinliyor" },
    { name: "Kitap Okuyor", value: "Kitap Okuyor" },
    { name: "Oyun Oynuyor", value: "Oyun Oynuyor" },
    { name: "Alışverişte", value: "Alışverişte" },
    { name: "Spor Salonunda", value: "Spor Salonunda" },
    { name: "Yürüyüşte", value: "Yürüyüşte" },
    { name: "Koşuyor", value: "Koşuyor" },
    { name: "Bisiklet Sürüyor", value: "Bisiklet Sürüyor" },
    { name: "Egzersiz Yapıyor", value: "Egzersiz Yapıyor" },
    { name: "Kahve İçiyor", value: "Kahve İçiyor" },
    { name: "Sohbet Ediyor", value: "Sohbet Ediyor" },
    { name: "Fotoğraf Çekiyor", value: "Fotoğraf Çekiyor" },
    { name: "Resim Yapıyor", value: "Resim Yapıyor" },
    { name: "Yemek Yapıyor", value: "Yemek Yapıyor" },
    { name: "Bahçede Çalışıyor", value: "Bahçede Çalışıyor" },
    { name: "Alışveriş Yapıyor", value: "Alışveriş Yapıyor" },
    { name: "Plajda", value: "Plajda" },
    { name: "Dağda", value: "Dağda" },
    { name: "----- ÖZEL DURUMLAR -----", value: "9999" },
    { name: "Doğum Gününü Kutluyor", value: "Doğum Gününü Kutluyor" },
    { name: "Evleniyor", value: "Evleniyor" },
    { name: "Yeni İşte", value: "Yeni İşte" },
    { name: "Yeni Evde", value: "Yeni Evde" },
    { name: "Yıl Dönümü", value: "Yıl Dönümü" },
    { name: "Mezuniyet", value: "Mezuniyet" },
    { name: "Sınava Çalışıyor", value: "Sınava Çalışıyor" },
    { name: "Yarışmaya Hazırlanıyor", value: "Yarışmaya Hazırlanıyor" },
    { name: "Yeni Projede", value: "Yeni Projede" },
    { name: "Yeni Bebek", value: "Yeni Bebek" },
    { name: "Yardım Kampanyasında", value: "Yardım Kampanyasında" },
    { name: "Sağlık Kontrolünde", value: "Sağlık Kontrolünde" },
    { name: "Hastanede", value: "Hastanede" },
    { name: "Partide", value: "Partide" },
    { name: "Etkinlikte", value: "Etkinlikte" },
    { name: "Konserde", value: "Konserde" },
    { name: "Festivalde", value: "Festivalde" },
    { name: "Seyahate Hazırlanıyor", value: "Seyahate Hazırlanıyor" },
    { name: "İş Görüşmesinde", value: "İş Görüşmesinde" },
    { name: "Yeni Yıl Kutlamasında", value: "Yeni Yıl Kutlamasında" }
];    
