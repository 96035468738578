import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { dateIntegrator, getLanguageNameByCode } from '../../Components/Helper.js'; 
import { useSelector, useDispatch } from "react-redux";
import { ProfileMenu } from "../../Components/TabMenu.js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Profile() {

  const lang = useSelector((state) => state.language.value);

  const navigate = useNavigate();
  const [profileInformation, setProfileInformation] = useState();

  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [gsm, setGsm] = useState();

  const getProfileInformation = async () => {
 
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-profile-info`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        console.log(result?.data?.data);
        setProfileInformation(result?.data?.data);
        setName(result?.data?.data?.name);
        setSurname(result?.data?.data?.surname);
        setGsm(result?.data?.data?.gsm);
      }
    } catch (error) {
      console.log(error)
    }
    return true;
  }

  const updateProfileInformation = async () => {
 
    let reqBody = {
      name:name,
      surname:surname,
      gsm:gsm
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/update-profile-info`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.result && result?.data?.result == "Success") { 

        toast.success('👌 '+"Bilgileriniz güncellendi.", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 
    } else {
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    } 
    getProfileInformation(); 
  }, []);
 
  return (
    <>
      <div className="container">
        <div className="column1">
          <div className="left-side-menu">
            <div>
            <LeftMenu selected="Profile"/>
            </div>
          </div>
        </div>
        <div className="column2-settings"> 
          <div className="header">
            <b>Profile</b>
            <div className="nav-icons"> 
            <LanguageSelection /> 
            </div> 
          </div>  
     
    <ProfileMenu selected="Profile"/>
    
    {/* <label>Aktif Dil</label>  
    <input type="text" className="form-control" autocomplete="off" value={getLanguageNameByCode(lang)} disabled />
    <br /> <br />  */}
    
    <div class="form-double-container"> 
        <div className="half-width"> 
            <form className="login-form"> 
            <h4>Kişisel Bilgiler</h4> 
            <div className="form-group">
            <label>İsim</label>
            <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} value={name} />
            </div>
    
            <div className="form-group">
            <label>Soyisim</label>
            <input type="text" className="form-control" autocomplete="off" onChange={(e) => setSurname(e.target.value)} value={surname}/>
            </div>
            
            <div className="form-group">
            <label>GSM</label>
            <input type="text" className="form-control" autocomplete="off" onChange={(e) => setGsm(e.target.value)} value={gsm} />
            </div>
    
            <div className="form-group">
            <label>Email</label>
            <input type="text" className="form-control" autocomplete="off" value={profileInformation?.email} disabled />
            </div>
    
            <div className="form-group">
            <label>Üyelik Tarihi</label>
            <input type="text" className="form-control" autocomplete="off" value={dateIntegrator(profileInformation?.created_at, "long")} disabled />
            </div>

            <span  style={{ cursor: "pointer", textAlign:'center' }}  onClick={() => updateProfileInformation()} class="btn-danger">Kişisel Bilgilerimi Güncelle</span>
            </form> 

        </div> 
        <div className="half-width"> 
        <form className="login-form"> 
        <h4>Açıklamalar</h4>
             <ul className="settingDescriptions">
              <li>Sitede kullandığınız profil bilgilerinizi güncelleyebilirsiniz.</li>
              <li>Güvenlik bölümünden istediğiniz alanları gizli ya da açık yapabilirsiniz.</li>
             </ul>
            </form> 
        </div> 
    </div>
     
        </div> 
      </div>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

    </>
      );
}
 
export default Profile;
