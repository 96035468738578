import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from '../../Components/Menu.js'; 
import { PrivacyMenu } from '../../Components/TabMenu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
  
function Privacy() {

  const navigate = useNavigate();
  
  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Privacy"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Gizlilik</b>
        <div className="nav-icons">
        <LanguageSelection /> 
        </div> 
      </div>  
  
<PrivacyMenu selected="TwoFa"/>
  
<div class="form-double-container"> 
    <div className="half-width"> 
        <form className="login-form"> 
        <h4>2FA Durum Değişikliği</h4> 

        {/* <div className="form-group">
        <label>İsim</label>
        <input type="text" className="form-control" autocomplete="off" value={profileInformation?.name} />
        </div>
 
        <span  style={{ cursor: "pointer", textAlign:'center' }} class="btn-danger">Update</span> */}
        <br /><br /><br />
        Yapım Aşamasında
        <br /><br /><br />
        </form> 
    </div> 
    <div className="half-width"> 
    <form className="login-form"> 
    <h4>Açıklamalar</h4>
             <ul className="settingDescriptions">
              <li>İki aşamalı doğrulama ile hesabınızı tam olarak güvene alabilirsiniz.</li>
              <li>İki aşamalı doğrulama email ya da google authenticator ile yapılmaktadır.</li>
             </ul>
        </form> 
    </div> 
</div>  

    </div> 
  </div>
</>
  );
}
 

export default Privacy;
