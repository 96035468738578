import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import { languages } from '../../Constants/Languages.js';   
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { ContactMenu } from '../../Components/TabMenu.js'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.bgColor, // Set background color based on bgColor property
      color: state.isSelected ? "#888" : "#333", // Text color
      padding: 8,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#888", // Change text color of the selected value
    }),
    input: (provided) => ({
      ...provided,
      color: "#888", // Change text color while typing
    }),
  };

function Contacts() {

  const navigate = useNavigate();

  const endOfPageRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showLangs, setShowLangs] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [selectedOption, setSelectedOption] = useState('');
 
 
    const toggleLangSelector = () => {
      setShowLangs(!showLangs);
    }
 
    const addContact = () => {
      
      let contactId = selectedOption?.value;

      try {
        const result = axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/add-user`,
          {friend_id:contactId},
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
 
      toast.success('👌 '+"Kullanıcı listenize eklendi", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 

      } catch (error) {}
      console.log(selectedOption);
    }
 
    const changeLanguage = (newLang) => {

      try {
        const result = axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/settings/change-default-language`,
          {lang:newLang},
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
      } catch (error) {}

      setLang(newLang);
      localStorage.setItem("lang",newLang);
 
    }
 
  let token = localStorage.getItem("token");
  const [friends, setFriends] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);

  const [activeFriend, setActiveFriend] = useState();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [showSettings, setShowSettings] = useState(false);
  const [showUserSearch, setShowUserSearch] = useState(false);
   
  const [showMenu, setShowMenu] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  const handleUserSelect = async (selectedOption) => {
    setSelectedOption(selectedOption);
  }

  const loadOptions = (inputValue, callback) => {
    // Simulate a call to a backend API which returns a promise

    axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/search-user`,
        {keyword:inputValue},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      )  
      .then(data => {
        // Transform the data into the format { value, label }
        console.log(inputValue);
        console.log(data);
        const options = data.data.data.map(item => ({
          value: item.id,
          label: item.name + " " + item.surname + " (" + getLanguageNameByCode(item.lang) + ")",
          icon: "/images/flags/"+item.lang+".png"
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        callback([]);
      });
 
  }

 
  
  const Option = props => {
    return (
      <components.Option {...props}>
        <img src={props.data.icon} style={{ width: 20, height: 20, marginRight: 10 }} alt="" />
        {props.data.label}
      </components.Option>
    );
  };




    

 



  function getLanguageNameByCode(code) {
    const language = languages.find(lang => lang.code === code);
    return language ? language.name : 'Language not found';
  }
 

 

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
    
        <div>
        <LeftMenu selected="Contacts"/> 
        </div>
         
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Kontak</b>
        <div className="nav-icons"> 
        <LanguageSelection /> 
        </div> 
      </div>  

      <ContactMenu selected="Contacts"/>
 
      <div class="form-double-container"> 
    <div className="half-width"> 
    <div style={{padding:'20px'}}>
    
    <AsyncSelect
        className="form-control" 
        value={selectedOption}
        onChange={handleUserSelect}
        isSearchable={true} 
        styles={customStyles} 
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        placeholder="İsim ya da Soyisim yazın"
        components={{ Option }}
    />      
    
    <br />
    
    {selectedOption 
    ?
    <>
    
    <p align='center'>
    <img src={selectedOption.icon} style={{width:'50px'}}/> 
    <br /><br />
    Add {selectedOption.label}
     
    <br /><br />
    
    <div
      onClick={() => addContact()}
        style={{
        cursor: 'pointer',
        width:'300px', 
        height:'100px', 
        borderRadius:'10px', 
        color:'#fff', 
        backgroundColor:'#4EBD3B',
        fontSize:'18pt',
        fontWeight:'bolder',
        placeItems: 'center',
        display: 'grid' 
        }}>(+) ADD</div>
    </p>
    
    </> 
    :
    <>
    
    <p align='center'> 
    Kişi listenize eklemek için arama yapın.
    </p>
    
    </>
    }
    
    </div>
    </div> 
    
</div> 



 




    </div>
 
  </div>


  <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />


</>
  );
}
 

export default Contacts;
