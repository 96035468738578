import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../Features/languageSlice";
import Select, { components } from "react-select";
import { languagesAll } from "../Constants/LanguagesAll.js";
import axios from "axios";

let options = languagesAll;
// Function to sort languages by their label alphabetically
options.sort((a, b) => a.label.localeCompare(b.label));
  
// Custom component to render options with icons
const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <div style={{ display: "flex", paddingTop: "5px" }}>
      <div>
        <img
          src={`/images/flags/${props.data.value}.png`}
          style={{ width: 24, height: 24, marginRight: 10 }}
        />
      </div>
      <div style={{ paddingTop: "2px" }}>{props.data.label}</div>
      {/* <div style={{ paddingTop: "2px", fontSize:'7pt', color:'#888' }}>&nbsp;({props.data.label})</div> */}
    </div>
  </Option>
);

const IconSingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div style={{ display: "flex", paddingTop: "5px", width:'150px', minWidth:'150px' }}>
      <div>
        <img
          src={`/images/flags/${props.data.value}.png`}
          style={{ width: 24, height: 24, marginRight: 10 }}
          alt=""
        />
      </div>
      <div style={{ paddingTop: "2px" }}>{children}</div>
    </div>
  </components.SingleValue>
);


export const LanguageSelection = ({ selected }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();

  // GET
  const language = useSelector((state) => state.language.value);

  const handleChange = (selectedOption) => {
    changeLanguage(selectedOption);
    console.log("Selected value:", selectedOption.value);
    dispatch(setLanguage(selectedOption.value));
    localStorage.setItem("lang", selectedOption.value);
    setSelectedOption(selectedOption);
  }

  const changeLanguage = (newLang) => {

    try {
      const result = axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/settings/change-default-language`,
        {lang:newLang.value},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      
    } catch (error) {
      console.log(error)
    }
    return 1;
  }

  useEffect(() => {
    // Determine the default option, for example, by value
    const defaultOption = options.find((option) => option.value === language);
    setSelectedOption(defaultOption);
  }, []);

  return (
    <Select
      options={options}
      defaultValue={options[0]}
      value={selectedOption}
      onChange={handleChange}
      className="basic-single"
      classNamePrefix="select"
      components={{ Option: IconOption, SingleValue: IconSingleValue }}
      styles={{ container: (base) => ({ ...base, width: 200 }) }}
    />
  );
};
