import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { dateIntegrator, getLanguageNameByCode } from '../../Components/Helper.js'; 
import { useSelector, useDispatch } from "react-redux";
import { ProfileMenu } from "../../Components/TabMenu.js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Profile() {

  const lang = useSelector((state) => state.language.value);

  const navigate = useNavigate();
  const [profileInformation, setProfileInformation] = useState();

  const [whatsapp, setWhatsapp] = useState();
  const [telegram, setTelegram] = useState();
  const [linkedin, setLinkedin] = useState();
  const [twitter, setTwitter] = useState();
  const [other, setOther] = useState();
 
  const getProfileInformation = async () => {
 
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-profile-info`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        console.log(result?.data?.data);
        setProfileInformation(result?.data?.data);
        setWhatsapp(result?.data?.data?.whatsapp);
        setTelegram(result?.data?.data?.telegram);
        setLinkedin(result?.data?.data?.linkedin);
        setTwitter(result?.data?.data?.twitter);
        setOther(result?.data?.data?.other);
      }
    } catch (error) {
      console.log(error)
    }
  }

 
  const updateAccessInformation = async () => {
 
    let reqBody = {
      whatsapp:whatsapp,
      telegram:telegram,
      linkedin:linkedin,
      twitter:twitter,
      other:other
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/update-access-info`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.result && result?.data?.result == "Success") { 

        toast.success('👌 '+"Bilgileriniz güncellendi.", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 
    } else {
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    } 
    getProfileInformation(); 
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Profile"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Profile</b>
        <div className="nav-icons"> 
        <LanguageSelection /> 
        </div> 
      </div>  
 
<ProfileMenu selected="AccessInformation"/>
 
<div class="form-double-container"> 
<div className="half-width"> 
          <form className="login-form"> 
            <h4>Alteratif Erişim Kanalları</h4>  
 
            <div className="form-group">
            <label>Whatsapp</label>
            <input type="text" className="form-control" autocomplete="off" onChange={(e) => setWhatsapp(e.target.value)} value={whatsapp}  />
            </div> 
 
            <div className="form-group">
            <label>Telegram</label>
            <input type="text" className="form-control" autocomplete="off" onChange={(e) => setTelegram(e.target.value)} value={telegram} />
            </div> 

            <div className="form-group">
            <label>Linkedin</label>
            <input type="text" className="form-control" autocomplete="off" onChange={(e) => setLinkedin(e.target.value)} value={linkedin} />
            </div> 

            <div className="form-group">
            <label>Twitter</label>
            <input type="text" className="form-control" autocomplete="off" onChange={(e) => setTwitter(e.target.value)} value={twitter} />
            </div> 

            <div className="form-group">
            <label>Other</label>
            <input type="text" className="form-control" autocomplete="off" onChange={(e) => setOther(e.target.value)} value={other} />
            </div> 
 
            <span  style={{ cursor: "pointer", textAlign:'center' }} class="btn-danger" onClick={() => updateAccessInformation()}>Erişim Bilgilerimi Güncelle</span>
            </form> 
        </div> 
    <div className="half-width"> 
        <form className="login-form"> 
        <h4>Açıklamalar</h4>
             <ul className="settingDescriptions">
              <li>Farklı kanallara ait iletişim bilgilerinizi güncelleyebilirsiniz.</li>
              <li>Güvenlik bölümünden istediğiniz alanları gizli ya da açık yapabilirsiniz.</li>
             </ul>
            </form> 
        </div> 
</div>
 
    </div> 
  </div>

  <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

</>
  );
}
 
export default Profile;
