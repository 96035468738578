import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { languages } from '../../Constants/Languages.js';
import { BottomMenu } from '../../Components/Menu.js'; 

function Chat() {

  const navigate = useNavigate();

  const endOfPageRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showLangs, setShowLangs] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showColumn2, setShowColumn2] = useState(true);

    // Function to handle changes in the search box
    const handleSearchChange = (event) => { 
        setSearchTerm(event.target.value);
        let tmpSearchTerm = event.target.value;
        let tmpAllFriends = friends; 
        let filtered = tmpAllFriends.filter(friend =>
          friend.name.toLowerCase().includes(tmpSearchTerm) ||
          friend.surname.toLowerCase().includes(tmpSearchTerm)
        ); 
        setFilteredFriends(filtered); 
    }
 
    const emojis = ['🙂', '🤣', '👍', '🚀', '🌟', '❤️', '😘', '😡', '🙁', '😳'];

    const addEmoji = (emoji) => {
      setInputValue(inputValue + emoji);
      toggleEmojiPicker(!showEmojis);
    };

    const toggleEmojiPicker = () => {
        setShowEmojis(!showEmojis);
    };

    const toggleLangSelector = () => {
      setShowLangs(!showLangs);
    }

    const toggleSettings = () => {
      setShowSettings(!showSettings);
    }

    const toggleUserSearch = () => {
      setShowUserSearch(!showUserSearch);
    }
 
    const changeLanguage = (newLang) => {

      try {
        const result = axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/settings/change-default-language`,
          {lang:newLang},
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
      } catch (error) {}

      setLang(newLang);
      localStorage.setItem("lang",newLang);
 
    }
 
  let token = localStorage.getItem("token");
  const [friends, setFriends] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);

  const [activeFriend, setActiveFriend] = useState();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [showSettings, setShowSettings] = useState(false);
  const [showUserSearch, setShowUserSearch] = useState(false);
   
  const [showMenu, setShowMenu] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  const handleSendMessage = () => {
      handleSubmit();
  }
  
  function getLanguageNameByCode(code) {
    const language = languages.find(lang => lang.code === code);
    return language ? language.name : 'Language not found';
  }

  const handleSubmit = async () => {
    let reqBody = {
      destination_id: activeFriend?.friend_id,
      source_lng: lang,
      message_org: inputValue,
      destination_lng: activeFriend.lang,
    };

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/send-message`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
    } catch (error) {}

    setInputValue("");
  };

  let sourceId = localStorage.getItem("userid");
  
  const selectFriend = (item) => {
    setMessages([]);
    setActiveFriend(item);
    setShowColumn2(prev => !prev);
    getMessages();
    setScrollDown(true);
  };

  const listFriends = async () => {

    let latestStatus = [];

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-latest-message-status`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        latestStatus = result?.data?.data;
      }
    } catch (error) {}

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/list-friends`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
 
        let tmpFriends = result?.data?.data;
        const enhancedFriends = tmpFriends.map(friend => {
          // Find a status that matches the friend_id either in source_id or destination_id
          const status = latestStatus.find(status => 
              status.source_id === friend.friend_id || status.destination_id === friend.friend_id);
      
          // If a matching status is found, merge the friend object with the status
          if (status) {
              return { ...friend, latestStatus: status };
          }
          // If no status is found, return the friend object as is
          return friend;
        });


        let tmpAllFriends = enhancedFriends;
        let filtered = tmpAllFriends.filter(friend =>
          friend.name.toLowerCase().includes(searchTerm) ||
          friend.surname.toLowerCase().includes(searchTerm)
        );
       
        setFriends(filtered);
        setFilteredFriends(filtered); 
 
        console.log(searchTerm);

        // setFriends(enhancedFriends);
        // setFilteredFriends(enhancedFriends);
      }
    } catch (error) {}
 
  }

  const getMessages = async () => { 
    if (activeFriend?.friend_id) {
      let reqBody = {
        destination_id: activeFriend?.friend_id,
      };

      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/get-message`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ` + localStorage.getItem("token"),
            },
          }
        );
        if (result?.data?.data) {
          setMessages(result.data.data);
        }
      } catch (error) {}
    }
  }

  const dateIntegrator = (dateValue) => {
    let formattedDateValue = '';

    if (dateValue) {
        const dateObject = new Date(dateValue);  // Convert to a Date object
        if (!isNaN(dateObject.getTime())) {  // Check if the dateObject is valid
            formattedDateValue = dateObject.toISOString().split('T')[0] +" "+ dateObject.toISOString().split('T')[1].substring(0, 8);
        } else {
            console.error('Invalid date');
        }
    } else {
        console.error('dateValue is undefined or null');
    }
    return formattedDateValue;
  }

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
 
    const intervalId = setInterval(() => {
      // Call your function here
      getMessages();
    }, 500);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [activeFriend]);

  useEffect(() => {
    listFriends();
    const intervalId = setInterval(() => {
      // Call your function here
      listFriends();
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [searchTerm]);


  useEffect(() => {
    let intervalId2;
    if (scrollDown) {
        intervalId2 = setInterval(() => {
            if (endOfPageRef.current) {
                endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
                setScrollDown(false); // Disable further scrolling
            }
        }, 1250);
    }

    // Clear interval on cleanup
    return () => {
        if (intervalId2) clearInterval(intervalId2);
    };
}, [scrollDown]); // Dependency on scrollDown ensures interval resets when scrollDown changes
 
  return (
<>
  <div className="container">
 
  <div className="column2" style={{ display: showColumn2 ? 'block' : 'none' }}> 
  <div className="headerMobile">
      <div className="header">
        <b>Chat</b>
        <div className="nav-icons" onClick={toggleLangSelector}>
          <span style={{ fontSize: "0.8em", paddingTop: 1 }}>{getLanguageNameByCode(lang)}</span>&nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
          <span style={{ width: 15, height: 15, marginTop: 5 }}>&nbsp;</span>
          <img
            src={`/images/flags/${lang}.png`}
            style={{ width: 25 }}
          />
          {showLangs && (
                <div style={{
                    display: 'flex', 
                    position:'absolute', 
                    top:'0px', 
                    left:'-100px',
                    height:'450px', 
                    width:'185px',
                    padding:'12px',
                    backgroundColor:'#333',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRadius:'10px',
                    zIndex:999
                    }}>
                    <span style={{color:'#ddd'}}>
                      {languages.map((language, index) => (
                        <table>
                          <tr
                            onClick={(e) => {
                              e.preventDefault();  // Prevent the default action of the link
                              changeLanguage(language.code);
                            }}
                          >
                            <td><img src={`/images/flags/${language.code}.png`} style={{ width: 25 }}/> </td>
                            <td valign="middle"> &nbsp; {language.name}</td>
                          </tr>
                        </table> 
                      ))}
                    </span>
                </div>
            )}
        </div>
 
      </div>
      <div className="search-container">
        <svg
          className="search-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx={11} cy={11} r={8} />
          <line x1={21} y1={21} x2="16.65" y2="16.65" />
        </svg>
        <input
          type="text"
          className="searchbox"
          placeholder="Search your friends ..."
          value={searchTerm} // Controlled component
          onChange={handleSearchChange} // Handle changes in the input
        />
      </div>
  </div>
      <div className="chat-list" style={{marginTop:'100px'}}>

      {filteredFriends ? (
                <>
                  {filteredFriends?.map((item) => ( 
          <div
                      className={
                        activeFriend?.friend_id == item?.friend_id
                          ? "chat-box active"
                          : "chat-box"
                      }
                      onClick={() => selectFriend(item)}
                    >
          <div className="img-box">
            <div className="profile-circle">
            {item.name.charAt(0)}
            {item.surname.charAt(0)} 
            </div>
            <img
              className="small-flag"
              src={`/images/flags/${item?.lang}.png`}
              alt=""
            />
          </div>
          <div className="chat-details">
            <div className="text-head">
              <div>
              {item?.name} {item?.surname}{" "}
                            <span style={{ fontSize: "0.5em", marginTop: 2 }}>
                              ({item?.lang})
                            </span>
              </div>
              <p>{dateIntegrator(item?.latestStatus?.created_at)}</p>
            </div>
            <div className="text-message">
              <p>{item?.latestStatus?.message_org?.substring(0, 25)} ...</p>
              <b>?</b>
            </div>
          </div>
        </div>
                   ))}
                   </>
                 ) : (
                   <></>
                 )}
 

      </div>
  </div>
 

  <div className="column3" style={{ display: showColumn2 ? 'none' : 'block' }}>
      <div className="headerMobile" style={{marginBottom:'100px'}}>
      <div className="right-header">
        <div className="icon-and-name">

        <div onClick={() => setShowColumn2(!showColumn2)}>
        <i class="fa fa-angle-left" style={{fontSize:'20pt'}} aria-hidden="true"></i>
        &nbsp;&nbsp;
        </div>  

          <div
            className="profile-circle"
            style={{ height: 45, width: 45, marginRight: 15 }}
          >
                    {activeFriend?.name.charAt(0)}
                    {activeFriend?.surname.charAt(0)}
          </div>
          <div>
            <h4>
            {activeFriend?.name} {activeFriend?.surname}
                  <br />
                  <span>Online ({activeFriend?.lang})</span>
            </h4>
          </div>
        </div>
        <div className="nav-icons">
          <img
            src={`/images/flags/${activeFriend?.lang}.png`}
            style={{ width: 45, height: 45 }}
          />
 
          <i className="fa-solid fa-ellipsis-vertical" aria-hidden="true" />
        </div>
      </div>
      </div>
      <div className="chat-container">
              {messages ? (
                <>
                  {messages?.map((item) => (
                    <>
                      {item?.source_id == sourceId ? (
                        <div className="message-box my-message">
                          <p>
                            {item.message_org}
                            <br />
                            <span style={{color:'888',fontSize:'9pt'}}><i>{item.message_translated}</i></span>  
                            <span>{dateIntegrator(item?.created_at)}</span>
                          </p>
                        </div>
                      ) : (
                        <div className="message-box friend-message">
                          <p>
                            {item?.message_translated}
                            <br />
                            <span style={{color:'888',fontSize:'9pt'}}><i>{item.message_org}</i></span> 
                            <span>{dateIntegrator(item?.created_at)}</span>
                          </p>
                        </div>
                      )} 
                    </>
                  ))}
                <div ref={endOfPageRef} />
                </>
                
              ) : (
                <></>
              )}
              
            </div>
      <div className="chatbox-input-mobile">
        <div style={{ width: 50, textAlign: "center" }}>
          <i
            className="icon fa fa-smile-o clickable"
            style={{ fontSize: "25pt" }}
            aria-hidden="true"
            onClick={toggleEmojiPicker}
          />
        </div>
 
            {showEmojis && (
                <div style={{
                    display: 'flex', 
                    position:'absolute', 
                    top:'-60px', 
                    left:'25px',
                    height:'40px', 
                    backgroundColor:'#999',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRadius:'10px'
                    }}>
                    {emojis.map((emoji, index) => (
                        <span key={index} onClick={() => addEmoji(emoji)} style={{padding:10}}>{emoji}</span>
                    ))}
                </div>
            )}
 
        <div style={{ position: "relative", flexGrow: 1, textAlign: "center" }}>
          <input
            type="text"
            className="chatbox-input-box"
            placeholder="Type something..."
            value={inputValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress} // Call handleKeyPress on key press
          />
 
          <i 
          onClick={handleSendMessage}
          className="fa fa-arrow-circle-up send-icon" aria-hidden="true" />
        </div>
        <div style={{ width: 50, textAlign: "center" }}>
          <i className="fa fa-plus-circle" aria-hidden="true" />
        </div>
      </div>
    </div>


<BottomMenu selected="Chat"/> 

</div>
</>
  );
 
}

export default Chat;
