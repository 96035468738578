import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import { languages } from '../../Constants/Languages.js';  
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { timeZones } from '../../Constants/TimeZones.js'; 
import { ContactMenu } from "../../Components/TabMenu.js";

function MyBlockedContacts() {

  const navigate = useNavigate();
  
   // State to track the selected timezone
   const [selectedTimeZone, setSelectedTimeZone] = useState(timeZones[12].value); // Default to UTC+00:00

   // Handle the selection change
   const handleChange = (event) => {
     setSelectedTimeZone(event.target.value);
   };

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Contacts"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Kontak</b>
        <div className="nav-icons">
        <LanguageSelection />
         
        </div> 
      </div>  
 
<ContactMenu selected="MyBlockedContacts"/>
 
<div class="form-double-container"> 
    <div className="half-width"> 
        <form className="login-form">  
        <br /><br /><br />
        Yapım Aşamasında
        <br /><br /><br />
        </form> 
    </div> 
</div>
   
    </div> 
  </div>
</>
  );
}
 

export default MyBlockedContacts;
