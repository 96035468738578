export const faqs = [
  {
    question: "Bu proje nedir?",
    answer:
      'Bu proje, kullanıcıların metin tabanlı iletişim kurabileceği bir platform geliştirmeyi amaçlamaktadır. WhatsApp ve Telegram benzeri bu platform, yalnızca yazılı mesajlaşmayı destekleyecek ve video veya sesli iletişim özelliklerini içermeyecektir. Ayrıca, kullanıcıların farklı dillerde iletişim kurabilmesini sağlayacak Çok Dilli Mesajlaşma ve "Bas Konuş" özelliklerine de sahiptir.',
  },
  {
    question: "Şifre değişikliği nasıl yapılır?",
    answer:
      'Kullanıcılar sistem erişim şifrelerini "Şifre Değişikliği" bölümünden değiştirebilirler. Şifreler en az 8 karakter uzunluğunda olmalı ve harf ile numaradan oluşmalıdır.',
  },
  {
    question: "Çok Dilli Mesajlaşma özelliği nedir?",
    answer:
      "Kullanıcılar istedikleri dili seçerler ve karşı taraf hangi dilde yazarsa yazsın, mesajlar seçilen dile tercüme edilerek iletilir. Bu özellik, dil engellerini ortadan kaldırarak kültürlerarası bağlantıları güçlendirmeyi hedefler.",
  },
  {
    question: '"Bas Konuş" özelliği nasıl çalışır?',
    answer:
      "Kullanıcı konuşur, sistem bu konuşmayı kaydeder, tercüme eder ve karşı tarafa hem tercüme edilmiş metin hem de tercüme edilmiş ses olarak iletir.",
  },
  {
    question: "Mesajlar nasıl yedeklenir?",
    answer:
      "Kullanıcılar kişi veya grup bazlı seçim yaparak önceki mesajlarını yedekleyebilirler.",
  },
  {
    question: "Dil ayarları nasıl yapılır?",
    answer:
      "Kullanıcılar uygulamanın tema dil ayarını ve gelen mesajların hangi dilde görüntüleneceğini seçebilirler.",
  },
  {
    question: "Mesajlar nasıl korunur?",
    answer:
      "Mesajlar uçtan uca şifrelenir, böylece sadece gönderici ve alıcı tarafından okunabilir.",
  },
  {
    question: "İstenmeyen kullanıcılar nasıl engellenir?",
    answer:
      "Kullanıcılar, istemedikleri kişileri bloklayabilir ve bu blokları istedikleri zaman kaldırabilirler.",
  },
  {
    question: "Gerçek zamanlı iletişim nasıl sağlanacak?",
    answer: "Gerçek zamanlı iletişim için LinguChat özel WebSocket desteği kullanılacaktır.",
  },
  {
    question: "Destek nasıl alınır?",
    answer:
      "Kullanıcılar, hazır dokümanları okuyarak sistem hakkında bilgi edinebilirler. Sorun yaşadıklarında veya anlamadıkları noktalarda kontak formunu doldurarak destek talebi gönderebilirler.",
  },
  {
    question: "Platform kaç dili desteklemektedir?",
    answer: "Platform, en çok kullanılan 30 dili desteklemektedir.",
  },
];
