// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translations
const resources = {
  tr: {
    translation: {
        greeting:	'Selam', 
    },
  },
  fr: {
    translation: {
      greeting: 'Bonjour le monde !',
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'tr', // Default language
    fallbackLng: 'tr', // Fallback language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
