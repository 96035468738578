import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { dateIntegrator, getLanguageNameByCode } from '../../Components/Helper.js'; 
import { useSelector, useDispatch } from "react-redux";
import { ProfileMenu } from "../../Components/TabMenu.js";
import { status } from "../../Constants/Status.js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Profile() {

  const lang = useSelector((state) => state.language.value);

  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState();
 
   // Handle the selection change
   const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  }

  const getProfileInformation = async () => {
 
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-profile-info`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        console.log(result?.data?.data);
        setSelectedStatus(result?.data?.data?.status_text);
       
      }
    } catch (error) {
      console.log(error)
    }
    return true;
  }

  const updateProfileStatus = async () => {
 
    let reqBody = {
      status_text:selectedStatus
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/update-profile-status`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.result && result?.data?.result == "Success") { 

        toast.success('👌 '+"Bilgileriniz güncellendi.", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 
    } else {
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    } 
    getProfileInformation();
 
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Profile"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Profile</b>
        <div className="nav-icons"> 
        <LanguageSelection /> 
        </div> 
      </div>  
 
<ProfileMenu selected="ProfileStatus"/>
 
<div class="form-double-container"> 
    <div className="half-width"> 
     
              <form className="login-form">
                <h4>Profil Statü Mesajı</h4>

                <div className="form-group">
                  <select value={selectedStatus} onChange={handleChange}>
                    {status.map((timezone, index) => (
                      <option key={index} value={timezone.value}>
                        {timezone.name}
                      </option>
                    ))}
                  </select>
                </div>  
                <span
                  style={{ cursor: "pointer", textAlign: "center" }}
                  class="btn-danger"
                  onClick={() => updateProfileStatus()}
                >
                  Genel Durumu Güncelle
                </span>
          
              </form>
 
    </div> 
    <div className="half-width"> 
        <form className="login-form"> 
        <h4>Açıklamalar</h4>
             <ul className="settingDescriptions">
              <li>Profil sayfanızdaki statü durumunuzu öntanımlı listeden seçebilirsiniz.</li>
              <li>Öntanımlı listeye kendi ekleme ve güncellemelerinizi yapabilirsiniz.</li>
             </ul>
            </form> 
        </div> 
</div>
 
    </div> 
  </div>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

</>
  );
}
 
export default Profile;
