import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { components } from "react-select";
import { languages } from '../../Constants/Languages.js';  
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { timeZones } from '../../Constants/TimeZones.js'; 
import { SettingsMenu } from "../../Components/TabMenu.js";

function Chat() {

  const navigate = useNavigate();
  
   // State to track the selected timezone
   const [selectedTimeZone, setSelectedTimeZone] = useState(timeZones[12].value); // Default to UTC+00:00

   // Handle the selection change
   const handleChange = (event) => {
     setSelectedTimeZone(event.target.value);
   };

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Settings"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Ayarlar</b>
        <div className="nav-icons">
        <LanguageSelection />
         
        </div> 
      </div>  
 
<SettingsMenu selected="Backup"/>
 
<div class="form-double-container"> 
    <div className="half-width"> 
        <form className="login-form"> 
        <h4>Yedekleme</h4> 

        {/* <div className="form-group">
        <label>İsim</label>
        <input type="text" className="form-control" autocomplete="off" value={profileInformation?.name} />
        </div>
 
        <span  style={{ cursor: "pointer", textAlign:'center' }} class="btn-danger">Update</span> */}
        <br /><br /><br />
        Yapım Aşamasında
        <br /><br /><br />
        </form> 
    </div> 
    <div className="half-width"> 
    <form className="login-form"> 
    <h4>Açıklamalar</h4>
             <ul className="settingDescriptions">
              <li>Kişi ya da grup bazlı seçim yaparak önceki mesajlarınızı yedekleyebilirsiniz.</li>
             </ul>
        </form> 
    </div> 
</div>
       
 
 

    </div> 
  </div>
</>
  );
}
 

export default Chat;
