import React from 'react';
import { Link } from "react-router-dom";

export const ProfileMenu = ({selected}) => {
    return (
        <> 
  {/* <Link to="/privacy">
          <div className="left-side-menu-item">
            <i
              className="fa fa-shield"
              style={{ 
                fontSize: "20pt", 
                color: selected === "Privacy" ? "#e47200" : "#ccc"
                }}
              aria-hidden="true"
            />
          </div>
          </Link>  */}


<div class="form-double-container">
<div className="half-width"> 

  <Link className="tab-title" to="/profile"
  style={{  
    color: selected === "Profile" ? "#E47200" : "#000"
    }}
  > 
  Kişisel Bilgiler
  </Link>
  &nbsp;|&nbsp;

  <Link className="tab-title" to="/access-information"
    style={{  
      color: selected === "AccessInformation" ? "#E47200" : "#000"
      }}
      >
  Erişim Bilgileri
  </Link>
  &nbsp;|&nbsp;

  <Link className="tab-title" to="/profile-view"
    style={{  
      color: selected === "ProfileView" ? "#E47200" : "#000"
      }}
  >
  Genel Görünüm
  </Link>
  &nbsp;|&nbsp;

  <Link className="tab-title" to="/profile-status"
    style={{  
      color: selected === "ProfileStatus" ? "#E47200" : "#000"
      }}
      >
  Durum
  </Link>
 

</div>
</div>
          </>
    )
};

export const SettingsMenu = ({selected}) => {
  return (
      <> 
 
<div class="form-double-container">
<div className="half-width"> 

<Link className="tab-title" to="/settings"
style={{  
  color: selected === "Settings" ? "#E47200" : "#000"
  }}
>
Dil Ayarları
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/timezone"
style={{  
  color: selected === "Timezone" ? "#E47200" : "#000"
  }}
>
Zaman Dilimi
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/date-preferences"
style={{  
  color: selected === "DatePreferences" ? "#E47200" : "#000"
  }}
>
Tarih Tercihleri
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/backup"
style={{  
  color: selected === "Backup" ? "#E47200" : "#000"
  }}
>
Yedekleme
</Link>
 
</div>
</div>
        </>
  )
}

export const PrivacyMenu = ({selected}) => {
  return (
      <> 
 
<div class="form-double-container">
<div className="half-width"> 

<Link className="tab-title" to="/privacy"
style={{  
  color: selected === "Privacy" ? "#E47200" : "#000"
  }}
>
Şifre Değişikliği
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/2Fa"
style={{  
  color: selected === "TwoFa" ? "#E47200" : "#000"
  }}
>
2Fa
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/blocked-users"
style={{  
  color: selected === "BlockedUsers" ? "#E47200" : "#000"
  }}
>
Bloklu Kullanıncılar
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/auto-msg-removal"
style={{  
  color: selected === "AutoMsgRemoval" ? "#E47200" : "#000"
  }}
>
Otomatik Mesaj Silme
</Link>
 
</div>
</div>
        </>
  )
}


export const ContactMenu = ({selected}) => {
  return (
      <> 
 
<div class="form-double-container">
<div className="half-width"> 

<Link className="tab-title" to="/contacts"
style={{  
  color: selected === "Contacts" ? "#E47200" : "#000"
  }}
>
  Kişi Arama
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/my-contacts"
style={{  
  color: selected === "MyContacts" ? "#E47200" : "#000"
  }}
>
Kontaklarım
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/my-blocked-contacts"
style={{  
  color: selected === "MyBlockedContacts" ? "#E47200" : "#000"
  }}
>
Bloklu Kontaklarım
</Link>
 
</div>
</div>
        </>
  )
}

export const SupportMenu = ({selected}) => {
  return (
      <> 
 
<div class="form-double-container">
<div className="half-width"> 

<Link className="tab-title" to="/info"
style={{  
  color: selected === "Contacts" ? "#E47200" : "#000"
  }}
>
  SSS (Sıkça Sorulan Sorular)
</Link>
&nbsp;|&nbsp;

<Link className="tab-title" to="/support-form"
style={{  
  color: selected === "SupportForm" ? "#E47200" : "#000"
  }}
>
Destek Formu
</Link>
  
</div>
</div>
        </>
  )
}