export const languages = [
    {
      code:'tr',
      name:'Türkçe'
    },
    {
      code:'en',
      name:'English'
    },
    {
      code:'de',
      name:'Deutsch'
    },
    {
      code:'fr',
      name:'Français'
    },
    {
      code:'it',
      name:'Italiano'
    },
    {
      code:'pt',
      name:'Português'
    },
    {
      code:'es',
      name:'Español'
    },
    {
      code:'ru',
      name:'Pусский'
    },
    {
      code:'az',
      name:'Azərbaycanca'
    },
    {
      code:'uz',
      name:'O\'zbek'
    },
    {
      code:'ae',
      name:' العربية'
    },
    {
      code:'cn',
      name:'中文'
    },
    


    {
      code:'bg',
      name:'Bulgarian'
    },
    {
      code:'el',
      name:'Greek'
    },
    {
      code:'ja',
      name:'Japanese'
    },
    {
      code:'ko',
      name:'Korean'
    },
    {
      code:'uk',
      name:'Ukrainian'
    }
  ]