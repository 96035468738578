import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { dateIntegrator, getLanguageNameByCode } from '../../Components/Helper.js'; 
import { useSelector, useDispatch } from "react-redux";
import { ProfileMenu } from "../../Components/TabMenu.js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Profile() {

  const lang = useSelector((state) => state.language.value);
  const navigate = useNavigate();
 
  const [profile, setProfile] = useState();
 
  const getProfileInformation = async () => { 
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-profile-info`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) { 
        setProfile(result?.data?.data?.profile);
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  const updateProfileView = async () => {
 
    let reqBody = {
      profile:profile
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/update-profile-view`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.result && result?.data?.result == "Success") { 

        toast.success('👌 '+"Bilgileriniz güncellendi.", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 
    } else {
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
    } catch (error) {
      console.log(error)
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
  }

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    } 
    getProfileInformation();
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Profile"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Profile</b>
        <div className="nav-icons"> 
        <LanguageSelection /> 
        </div> 
      </div>  
 
      <ProfileMenu selected="ProfileView"/>
 
    <div class="form-double-container"> 
    <div className="half-width"> 
        <form className="login-form"> 
        <h4>Profil Görünümüm</h4> 
 
            <div className="form-group">
            <label>Herkese açık</label>
            <input type="radio" name="profileSelection" 
             checked={profile === 'PUBLIC'}   
             onChange={() => setProfile('PUBLIC')}  
             className="form-control" />
            </div>

            <div className="form-group">
            <label>Sadece kontaklarıma açık</label>
            <input type="radio" name="profileSelection" 
            checked={profile === 'PRIVATE'}   
            onChange={() => setProfile('PRIVATE')}  
            className="form-control" />
            </div>

            <span  style={{ cursor: "pointer", textAlign:'center' }} class="btn-danger" onClick={() => updateProfileView()}>Profil Görünümümü Güncelle</span>
 
        </form> 
    </div> 
    <div className="half-width"> 
        <form className="login-form"> 
        <h4>Açıklamalar</h4>
             <ul className="settingDescriptions">
              <li>Kendi ekranınızla ilgili, arkaplan, font, renk gibi değişiklikleri yapabilirsiniz.</li>
              <li>Değişikliklerin sadece size özel ya da genel görünüme açık olmasını sağlayabilirsiniz.</li>
             </ul>
            </form> 
        </div> 
</div>
 
    </div> 
  </div>


  <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

</>
  );
}
 
export default Profile;
