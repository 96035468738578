import React from 'react';
import { Link } from "react-router-dom";

export const LeftMenu = ({selected}) => {
    return (
        <>

 
<div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', height:'100vh'}}>
        <div>
          <Link to="/chat"> 
          <div className="left-side-menu-item">  
            <i
              className="fa fa-home"
              style={{ 
              fontSize: "20pt", 
              color: selected === "Chat" ? "#e47200" : "#ccc"
              }}
              aria-hidden="true"
            />
          </div>
          </Link> 
          
          <Link to="/profile">
          <div className="left-side-menu-item">
            <i
              className="fa fa-user"
              style={{ 
                fontSize: "20pt", 
                color: selected === "Profile" ? "#e47200" : "#ccc"
                }}
              aria-hidden="true"
            />
          </div>
          </Link> 

          <Link to="/contacts" alt="Contacts">
          <div className="left-side-menu-item">
            <i
              className="fa fa-users"
              style={{ 
                fontSize: "20pt", 
                color: selected === "Contacts" ? "#e47200" : "#ccc"
                }}
              aria-hidden="true"
            />
          </div>
          </Link> 
         
          <Link to="/settings">
          <div className="left-side-menu-item">
            <i
              className="fa fa-cog"
              style={{ 
                fontSize: "20pt", 
                color: selected === "Settings" ? "#e47200" : "#ccc"
                }}
              aria-hidden="true"
            />
          </div>
          </Link>
          <Link to="/privacy">
          <div className="left-side-menu-item">
            <i
              className="fa fa-shield"
              style={{ 
                fontSize: "20pt", 
                color: selected === "Privacy" ? "#e47200" : "#ccc"
                }}
              aria-hidden="true"
            />
          </div>
          </Link>
          </div>

          <div>
          <Link to="/info"> 
          <div className="left-side-menu-item-reverse" style={{borderTop: '1px solid #888;'}}>  
            <i
              className="fa fa-info-circle"
              style={{ 
              fontSize: "20pt", 
              color: selected === "Info" ? "#e47200" : "#ccc"
              }}
              aria-hidden="true"
            />
          </div>
          </Link> 
          <Link to="/logout"> 
          <div className="left-side-menu-item-reverse">  
            <i
              className="fa fa-sign-out"
              style={{ 
              fontSize: "20pt", 
              color: "#ccc"
              }}
              aria-hidden="true"
            />
          </div>
          </Link> 
        </div>

        </div>
          </>
    )
};

export const BottomMenu = ({selected}) => {
    return (
      <div className="footer-menu-mobile">
      <Link to="/chat" className="footer-menu-item-mobile" style={{borderRight:'1px solid #888'}}>
      <div>
        <i
        className="fa fa-home"
        style={{ fontSize: "20pt", color: selected === "Chat" ? "#e47200" : "#ccc" }}
        aria-hidden="true"
        />
      </div>
      </Link> 
      <Link to="/contacts" className="footer-menu-item-mobile" style={{borderRight:'1px solid #888'}}>
      <div> 
        <i
        className="fa fa-user"
        style={{ fontSize: "20pt", color: selected === "Contacts" ? "#e47200" : "#ccc" }}
        aria-hidden="true"
        /> 
      </div>
      </Link> 
      <Link to="/settings" className="footer-menu-item-mobile">
      <div>
        <i
        className="fa fa-cog"
        style={{ fontSize: "20pt", color: selected === "Settings" ? "#e47200" : "#ccc" }}
        aria-hidden="true"
        />
      </div>
      </Link> 
</div>
    )
};
