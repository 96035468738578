import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Logout() {
  useEffect(() => {
    localStorage.setItem("userid", "");
    localStorage.setItem("token", "");
  }, []);
 
  return (  

<div className="myBody">
<div className="login-form-container">
  <form className="login-form">
    <h2>Logout</h2>
     
    <br /><br /><br />

    <p align='center'>Sistemden çıkış yaptınız.</p>

    <br /><br />

    <p align='center'>
     <Link to="/login">Login</Link> 
    </p>
           
  </form>
</div>
</div>


  );
}

export default Logout;
