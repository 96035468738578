import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { timeZones } from "../../Constants/TimeZones.js";
import { ContactMenu } from "../../Components/TabMenu.js";
import { dateIntegrator, getLanguageNameByCode } from '../../Components/Helper.js'; 

function MyContacts() {

  const navigate = useNavigate();
    
  const [searchTerm, setSearchTerm] = useState('');
  const [friends, setFriends] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);
  const [activeFriend, setActiveFriend] = useState();

  function getTimeZoneName(value) {
    const timeZone = timeZones.find(zone => zone.value === value);
    return timeZone ? timeZone.name : 'Time zone not found';
  }

  const selectFriend = (item) => { 
    setActiveFriend(item); 
  };

  const handleSearchChange = (event) => { 
    setSearchTerm(event.target.value);
    let tmpSearchTerm = event.target.value;
    let tmpAllFriends = friends; 
    let filtered = tmpAllFriends.filter(friend =>
      friend.name.toLowerCase().includes(tmpSearchTerm) ||
      friend.surname.toLowerCase().includes(tmpSearchTerm)
    ); 
    setFilteredFriends(filtered); 
  }

  const listFriends = async () => {

    let latestStatus = [];

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-latest-message-status`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        latestStatus = result?.data?.data;
      }
    } catch (error) {}

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/list-friends`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        let tmpFriends = result?.data?.data;
        const enhancedFriends = tmpFriends.map(friend => {
          // Find a status that matches the friend_id either in source_id or destination_id
          const status = latestStatus.find(status => 
              status.source_id === friend.friend_id || status.destination_id === friend.friend_id);
      
          // If a matching status is found, merge the friend object with the status
          if (status) {
              return { ...friend, latestStatus: status };
          }
          // If no status is found, return the friend object as is
          return friend;
        });
 
        let tmpAllFriends = enhancedFriends;
        let filtered = tmpAllFriends.filter(friend =>
          friend.name.toLowerCase().includes(searchTerm) ||
          friend.surname.toLowerCase().includes(searchTerm)
        );
        
        setFriends(filtered);
        setFilteredFriends(filtered);
        
        function findFriendByFriendId(friends, friendId) {
          // Use the .find() method to search for the friend with the given friend_id
          const friend = friends.find(friend => friend.friend_id === friendId);
        
          // Return the found friend, or undefined if no friend matches the friend_id
          return friend;
        }

        if (activeFriend?.friend_id) {
          let friend = findFriendByFriendId(filtered, activeFriend?.friend_id);
          console.log(friend);
          setActiveFriend(friend);          
        }
 
      }
    } catch (error) {
      console.log(error)
    }
 
  }

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }

    listFriends();
    
  }, [searchTerm]);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Contacts"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Kontak</b>
        <div className="nav-icons">
        <LanguageSelection />
         
        </div> 
      </div>  
 
<ContactMenu selected="MyContacts"/>
 
<div class="form-double-container"> 
    <div className="half-width"> 


<input
type="text"
className="searchbox"
placeholder="Listeniz içerisinde arama yapın ..."
value={searchTerm} // Controlled component
onChange={handleSearchChange} // Handle changes in the input
/>

<div className="column2-contacts" style={{marginTop:'15px'}}> 
<div className="chat-list chat-list-contacts">
{filteredFriends ? (
<>
{filteredFriends?.map((item) => ( 
<div
className={
activeFriend?.friend_id == item?.friend_id
? "chat-box active"
: "chat-box"
}
onClick={() => selectFriend(item)}
>
<div className="img-box">
<div className="profile-circle">
{item.name.charAt(0)}
{item.surname.charAt(0)} 
</div>
<img
className="small-flag"
src={`/images/flags/${item?.lang}.png`}
alt=""
/>
</div>
<div className="chat-details">
<div className="text-head">
<div>
{item?.name} {item?.surname}{" "}
<span style={{ fontSize: "0.5em", marginTop: 2 }}>
  ({getLanguageNameByCode(item?.lang)})
</span>
</div>
<p className="chat-date">
{dateIntegrator(item?.latestStatus?.created_at)}
</p>
</div>
<div className="text-message">
<p>{item?.latestStatus?.message_org?.substring(0, 41)} ...</p>

{/* <b>?</b> */}
</div>
</div>
</div>
))}
</>
) : (
<></>
)}
</div>
 </div>
    </div> 

<div className="half-width">
      {
      activeFriend 
      ?  
  <div style={{
    textAlign:'center', 
    display:'flex', 
    flexDirection:'column',
    justifyContent: 'center',
    alignItems: 'center'
    }}>
  
<div className="profile-circle" style={{ height: 80, width: 80}}>
{activeFriend?.name.charAt(0)}
{activeFriend?.surname.charAt(0)}
</div>
<br />
{activeFriend?.name} {activeFriend?.surname}
<br />
<div style={{textAlign:'left', width:'100%', marginTop:'25px'}}>

<table>
  <tr>
    <td><b>Whatsapp</b></td>
    <td>{activeFriend?.whatsapp}</td>
  </tr>
  <tr>
    <td><b>Twitter</b></td>
    <td>{activeFriend?.twitter}</td>
  </tr>
  <tr>
    <td><b>Linkedin</b></td>
    <td>{activeFriend?.linkedin}</td>
  </tr>
  <tr>
    <td><b>Telegram</b></td>
    <td>{activeFriend?.telegram}</td>
  </tr>
  <tr>
    <td><b>Location</b></td>
    <td>{activeFriend?.location}</td>
  </tr>
  <tr>
    <td><b>Timezone</b></td>
    <td>{getTimeZoneName(activeFriend?.timezone)}</td>
  </tr>
</table>
  
  <div style={{paddingLeft:'20px', paddingTop:'20px'}}>
  <span style={{ cursor: "pointer", textAlign: "center" }} class="btn-danger">Blokla</span>
  &nbsp;
  <span style={{ cursor: "pointer", textAlign: "center" }} class="btn-danger">Sessize Al</span>
   
  </div>

</div>
</div>
:

<div style={{width:'100%', display:'flex', alignItems:'center', height:'100%', justifyContent:'center'}}>
Detay görüntülemek için kontak isminin üzerine tıklayın.
</div>

}


</div>

</div>
   
    </div> 
  </div>
</>
  );
}
 

export default MyContacts;
