import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { languages } from '../../Constants/Languages.js';
import { BottomMenu } from '../../Components/Menu.js'; 

function SettingsMobile() {

  const navigate = useNavigate();

  const endOfPageRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showLangs, setShowLangs] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
 
    function getLanguageNameByCode(code) {
        const language = languages.find(lang => lang.code === code);
        return language ? language.name : 'Language not found';
    }
  
    const toggleLangSelector = () => {
      setShowLangs(!showLangs);
    }
 
    const changeLanguage = (newLang) => {

      try {
        const result = axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/settings/change-default-language`,
          {lang:newLang},
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
      } catch (error) {}

      setLang(newLang);
      localStorage.setItem("lang",newLang);
 
    }
 
  const [activeFriend, setActiveFriend] = useState();
  const [inputValue, setInputValue] = useState("");
  const [showMenu, setShowMenu] = useState(false);
 
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  
  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  return (
<>
  <div className="container">
 
  <div className="column2"> 
  <div className="headerMobile">
      <div className="header">
        <b>Settings</b>
        <div className="nav-icons" onClick={toggleLangSelector}>
          <span style={{ fontSize: "0.8em", paddingTop: 1 }}>{getLanguageNameByCode(lang)}</span>&nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
          <span style={{ width: 15, height: 15, marginTop: 5 }}>&nbsp;</span>
          <img
            src={`/images/flags/${lang}.png`}
            style={{ width: 25 }}
          />
          {showLangs && (
                <div style={{
                    display: 'flex', 
                    position:'absolute', 
                    top:'0px', 
                    left:'-100px',
                    height:'450px', 
                    width:'185px',
                    padding:'12px',
                    backgroundColor:'#333',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRadius:'10px',
                    zIndex:999
                    }}>
                    <span style={{color:'#ddd'}}>
                      {languages.map((language, index) => (
                        <table>
                          <tr
                            onClick={(e) => {
                              e.preventDefault();  // Prevent the default action of the link
                              changeLanguage(language.code);
                            }}
                          >
                            <td><img src={`/images/flags/${language.code}.png`} style={{ width: 25 }}/> </td>
                            <td valign="middle"> &nbsp; {language.name}</td>
                          </tr>
                        </table> 
                      ))}
                    </span>
                </div>
            )}
        </div> 
      </div>

      <div style={{padding:'20px'}}>
      <Link to="/logout">Logout</Link>
      </div>

  </div>
  </div>
  
<BottomMenu selected="Settings"/> 

</div>
</>
  ); 
} 
export default SettingsMobile;
