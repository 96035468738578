import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { languages } from '../../Constants/Languages.js';
import { LeftMenu } from '../../Components/Menu.js'; 
import { exists } from "i18next";
import { LanguageSelection } from '../../Components/LanguageSelection.js'; 
import { useSelector, useDispatch } from "react-redux";
import { dateIntegrator, getLanguageNameByCode } from '../../Components/Helper.js'; 

function Chat() {

  const navigate = useNavigate();

  const endOfPageRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showLangs, setShowLangs] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showColumn2, setShowColumn2] = useState(true);
  
  // GET
  const lang = useSelector((state) => state.language.value);

  const handleSearchChange = (event) => { 
    setSearchTerm(event.target.value);
    let tmpSearchTerm = event.target.value;
    let tmpAllFriends = friends; 
    let filtered = tmpAllFriends.filter(friend =>
      friend.name.toLowerCase().includes(tmpSearchTerm) ||
      friend.surname.toLowerCase().includes(tmpSearchTerm)
    ); 
    setFilteredFriends(filtered); 
  }

    const emojis = [
      '🙂','🙁',
      '👍','👎',
      '🤣','😘',
      '😡','😳',
      '🥳','😪',
      '🍾','💐', 
      '🚀','🌟', 
      '❤️' ];

    const addEmoji = (emoji) => {
      setInputValue(inputValue + emoji);
      toggleEmojiPicker(!showEmojis);
    };

    const toggleEmojiPicker = () => {
        setShowEmojis(!showEmojis);
    }
 
  const [friends, setFriends] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);

  const [activeFriend, setActiveFriend] = useState();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [showSettings, setShowSettings] = useState(false);
  const [showUserSearch, setShowUserSearch] = useState(false);
   
  const [showMenu, setShowMenu] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  const handleSendMessage = () => {
      handleSubmit();
  }
  
  
  const handleSubmit = async () => {
    
    const trimmedInput = inputValue.trim();

    let reqBody = {
      destination_id: activeFriend?.friend_id,
      source_lng: lang,
      message_org: trimmedInput,
      destination_lng: activeFriend.lang,
    }
  
    if (trimmedInput) {

      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/send-message`,
          reqBody,
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
        setScrollDown(true);
      } catch (error) {}

    }


    setInputValue("");
  }

  let sourceId = localStorage.getItem("userid");
   
  const selectFriend = (item) => {
    setMessages([]);
    setActiveFriend(item);
    setShowColumn2(prev => !prev);
    getMessages();
    setScrollDown(true);
  };

  const listFriends = async () => {

    let latestStatus = [];

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-latest-message-status`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        latestStatus = result?.data?.data;
      }
    } catch (error) {}

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/list-friends`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        let tmpFriends = result?.data?.data;
        const enhancedFriends = tmpFriends.map(friend => {
          // Find a status that matches the friend_id either in source_id or destination_id
          const status = latestStatus.find(status => 
              status.source_id === friend.friend_id || status.destination_id === friend.friend_id);
      
          // If a matching status is found, merge the friend object with the status
          if (status) {
              return { ...friend, latestStatus: status };
          }
          // If no status is found, return the friend object as is
          return friend;
        });


        let tmpAllFriends = enhancedFriends;
        let filtered = tmpAllFriends.filter(friend =>
          friend.name.toLowerCase().includes(searchTerm) ||
          friend.surname.toLowerCase().includes(searchTerm)
        );
        
                // Function to compare dates or fall back to names
                function compareItems(a, b) {
                  // Check if both items have a latestStatus and created_at
                  const aDate = a.latestStatus?.created_at;
                  const bDate = b.latestStatus?.created_at;
                
                  if (aDate && bDate) {
                    // Both items have dates, compare them in reverse order
                    return new Date(bDate) - new Date(aDate);
                  } else if (aDate) {
                    // Only A has a date, it should come first
                    return -1;
                  } else if (bDate) {
                    // Only B has a date, it should come first
                    return 1;
                  } else {
                    // Neither has a date, compare alphabetically by name
                    return a.name.localeCompare(b.name);
                  }
                }
                // Sort the array
                filtered.sort(compareItems);
 
        setFriends(filtered);
        setFilteredFriends(filtered);
        
        function findFriendByFriendId(friends, friendId) {
          // Use the .find() method to search for the friend with the given friend_id
          const friend = friends.find(friend => friend.friend_id === friendId);
        
          // Return the found friend, or undefined if no friend matches the friend_id
          return friend;
        }

        if (activeFriend?.friend_id) {
          let friend = findFriendByFriendId(filtered, activeFriend?.friend_id);
          setActiveFriend(friend);          
        }
 
      }
    } catch (error) {
      console.log(error)
    }
 
  }

  const getMessages = async () => { 
    if (activeFriend?.friend_id) {
      let reqBody = {
        destination_id: activeFriend?.friend_id,
      };

      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/get-message`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ` + localStorage.getItem("token"),
            },
          }
        );
        if (result?.data?.data) {
          setMessages(result.data.data);
        }
      } catch (error) {}
    }
  }
 
  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }

    const intervalId = setInterval(() => {
      // Call your function here
      getMessages();
    }, 200);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [activeFriend]);

  useEffect(() => {
    listFriends();
    const intervalId = setInterval(() => {
      // Call your function here
      listFriends();
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [searchTerm]);


  useEffect(() => {
    let intervalId2;
    if (scrollDown) {
        intervalId2 = setInterval(() => {
            if (endOfPageRef.current) {
                endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
                setScrollDown(false); // Disable further scrolling
            }
        }, 1250);
    }

    // Clear interval on cleanup
    return () => {
        if (intervalId2) clearInterval(intervalId2);
    };
}, [scrollDown]); // Dependency on scrollDown ensures interval resets when scrollDown changes

 

  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Chat"/>
        </div> 
      </div>
    </div>
    <div className="column2"> 
      <div className="header">
        <b>Chat</b>
        <div className="nav-icons">
           <LanguageSelection /> 
        </div>
 
      </div>
      <div className="search-container">
        <svg
          className="search-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx={11} cy={11} r={8} />
          <line x1={21} y1={21} x2="16.65" y2="16.65" />
        </svg>
        <input
          type="text"
          className="searchbox"
          placeholder="Listeniz içerisinde arama yapın ..."
          value={searchTerm} // Controlled component
          onChange={handleSearchChange} // Handle changes in the input
        />
      </div>
      <div className="chat-list">

      {filteredFriends ? (
                <>
                  {filteredFriends?.map((item) => ( 
          <div
                      className={
                        activeFriend?.friend_id == item?.friend_id
                          ? "chat-box active"
                          : "chat-box"
                      }
                      onClick={() => selectFriend(item)}
                    >
          <div className="img-box">
            <div className="profile-circle">
            {item.name.charAt(0)}
            {item.surname.charAt(0)} 
            </div>
            <img
              className="small-flag"
              src={`/images/flags/${item?.lang}.png`}
              alt=""
            />
          </div>
          <div className="chat-details">
            <div className="text-head">
              <div>
              {item?.name} {item?.surname}{" "}
                            <span style={{ fontSize: "0.5em", marginTop: 2 }}>
                              ({getLanguageNameByCode(item?.lang)})
                            </span>
              </div>
              <p className="chat-date">
              {dateIntegrator(item?.latestStatus?.created_at)}
              </p>
            </div>
            <div className="text-message">
              <p>{item?.latestStatus?.message_org?.substring(0, 41)} ...</p>
              
              {/* <b>?</b> */}
            </div>
          </div>
        </div>
                   ))}
                   </>
                 ) : (
                   <></>
                 )}
 

      </div>
    </div>
    {
      activeFriend 
      ?
      <div className="column3">
      <div className="right-header">
        <div className="icon-and-name">
          <div
            className="profile-circle"
            style={{ height: 45, width: 45, marginRight: 15 }}
          >
                    {activeFriend?.name.charAt(0)}
                    {activeFriend?.surname.charAt(0)}
          </div>
          <div>
            <h4>
            {activeFriend?.name} {activeFriend?.surname}  
            </h4>
          </div>
        </div>
        <div className="nav-icons">
        &nbsp;<span style={{fontSize:'8pt', paddingTop:'12px', paddingRight:'10px'}}>({getLanguageNameByCode(activeFriend?.lang)})</span>
          <img
            src={`/images/flags/${activeFriend?.lang}.png`}
            style={{ width: 45, height: 45 }}
          />
 
          <i className="fa-solid fa-ellipsis-vertical" aria-hidden="true" />
        </div>
      </div>
      <div className="chat-container">
        {/* {!isLoading 
        ?
        <> */}
              {messages ? (
                <>
                  {messages?.map((item) => (
                    <>
                      {item?.source_id == sourceId ? (
                        <div className="message-box my-message">
                          <p>
                            {item.message_org}
                            <br />
                            <span style={{color:'888',fontSize:'9pt'}}><i>{item.message_translated}</i></span>  
                            <span>{dateIntegrator(item?.created_at)}</span>
                          </p>
                        </div>
                      ) : (
                        <div className="message-box friend-message">
                          <p>
                            {item?.message_translated}
                            <br />
                            <span style={{color:'888',fontSize:'9pt'}}><i>{item.message_org}</i></span> 
                            <span>{dateIntegrator(item?.created_at)}</span>
                          </p>
                        </div>
                      )}
                    </>
                  ))}
                  <div ref={endOfPageRef} />
                </>
              ) : (
                <></>
              )}
          {/* </>
          :
          <>Loading</>
          } */}
          
            </div>
      <div className="chatbox-input">
        <div style={{ width: 50, textAlign: "center" }}>
          <i
            className="icon fa fa-smile-o clickable"
            style={{ fontSize: "25pt" }}
            aria-hidden="true"
            onClick={toggleEmojiPicker}
          />
        </div>
 
            {showEmojis && (
                <div style={{
                    display: 'flex', 
                    position:'absolute', 
                    top:'-60px', 
                    left:'25px',
                    height:'43px', 
                    backgroundColor:'#fff',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRadius:'8px'
                    }}>
                    {emojis.map((emoji, index) => (
                      <>
                        <span 
                          key={index}  
                          onClick={() => addEmoji(emoji)} style={{cursor: 'pointer', padding:12}}>{emoji}</span>
                          </>
                    ))}
                </div>
            )}
 
        <div style={{ position: "relative", flexGrow: 1, textAlign: "center" }}>
          <input
            type="text"
            className="chatbox-input-box"
            placeholder="Type something..."
            value={inputValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress} // Call handleKeyPress on key press
          />
 
          <i 
          onClick={handleSendMessage}
          className="fa fa-arrow-circle-up send-icon" aria-hidden="true" />
        </div>
        <div style={{ width: 50, textAlign: "center" }}>
          <i className="fa fa-plus-circle" aria-hidden="true" />
        </div>
      </div>
    </div>
      :
      <>
      <div style={{width:'60%', display:'flex', alignItems:'center', justifyContent:'center'}}>
        Konuşmaya başlamak için kişi ya da grup seçin.
      </div>
      </>
    }

  </div>
</>
  );
}
 

export default Chat;
