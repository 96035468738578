import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js';  
import { SupportMenu } from '../../Components/TabMenu.js'; 
 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Chat() {
  
  const [title, setTitle] = useState();
  const [detail, setDetail] = useState();

  const navigate = useNavigate();

  const sendForm = async () => {
 
    let reqBody = {
      title:title,
      detail:detail
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/support-form`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.result && result?.data?.result == "Success") { 

        toast.success('👌 '+"Formunuz gönderildi.", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 
    } else {
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Info"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Bilgi & Destek</b>
         
        <div className="nav-icons"  >
        <LanguageSelection />
        </div> 
      </div>  

      <SupportMenu selected="SupportForm"/>
 
      <div class="form-double-container"> 
        <div className="half-width"> 
            <form className="login-form">  
            <h4>Aşağıdaki Formu Doldurarak Bize Ulaşabilirsiniz</h4> 
            <div className="form-group">
            <label>Başlık</label>
            <input type="text" className="form-control"
            onChange={(e) => setTitle(e.target.value)}
            autocomplete="off"  />
            </div> 
            <div className="form-group">
            <label>Detaylar</label>
            <textarea className="form-control" onChange={(e) => setDetail(e.target.value)}></textarea> 
            </div> 
            <span  style={{ cursor: "pointer", textAlign:'center' }} 
            onClick={() => sendForm()}
            class="btn-danger">Gönder</span>
            </form> 
        </div>  
    </div>




    </div> 
  </div>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

</>
  );
}
 

export default Chat;
