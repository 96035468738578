export const languagesAll = [
    {
      value:'tr',
      label:'Türkçe'
    },
    {
      value:'en',
      label:'English'
    },
    {
      value:'uz',
      label:'O\'zbek'
    }, 
    {
      value:'az',
      label:'Azərbaycanca'
    },
    {
      value:'de',
      label:'Deutsch'
    },
    {
      value:'fr',
      label:'Français'
    },
    {
      value:'it',
      label:'Italiano'
    },
    {
      value:'pt',
      label:'Português'
    },
    {
      value:'es',
      label:'Español'
    },
    {
      value:'ru',
      label:'Pусский'
    },
    {
      value:'ae',
      label:' العربية'
    },
    {
      value:'cn',
      label:'中文'
    },
    {
      value: "bg",
      label: "български"
  },
  {
      value: "el",
      label: "Ελληνικά"
  },
  {
      value: "ja",
      label: "日本語"
  },
  {
      value: "ko",
      label: "한국어"
  },
  {
      value: "uk",
      label: "Українська"
  },
  {
      value: "vi",
      label: "Tiếng Việt"
  },
  {
      value: "th",
      label: "ไทย"
  },
  {
      value: "sv",
      label: "Svenska"
  },
  {
      value: "sl",
      label: "Slovenščina"
  },
  {
      value: "fa",
      label: "فارسی"
  },
  {
      value: "pl",
      label: "Polski"
  },
  {
      value: "ne",
      label: "नेपाली"
  },
  {
      value: "ms",
      label: "Bahasa Melayu"
  },
  {
      value: "lt",
      label: "Lietuvių"
  },
  {
      value: "hu",
      label: "Magyar"
  },
  {
      value: "id",
      label: "B.Indonesia"
  },
  {
      value: "ga",
      label: "Gaeilge"
  },
  {
      value: "cs",
      label: "Čeština"
  }
  ]