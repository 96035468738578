import { languagesAll } from "../Constants/LanguagesAll.js";

export const dateIntegrator = (dateValue, type="short") => {
    let formattedDateValue = '';

    if (dateValue) {
        const dateObject = new Date(dateValue);  // Convert to a Date object
        if (!isNaN(dateObject.getTime())) {  // Check if the dateObject is valid
            formattedDateValue = dateObject.toISOString().split('T')[0] +" "+ dateObject.toISOString().split('T')[1].substring(0, 8);
        } else {
            // console.error('Invalid date');
        }
    } else {
        // console.error('dateValue is undefined or null');
    }
    
    if (type=="short") {
        return formattedDateValue.substring(2, 16);
    } else {
        return formattedDateValue;
    }
    
}

export const getLanguageNameByCode = (code) => {
    const language = languagesAll.find(lang => lang.value === code);
    return language ? language.label : 'Language not found';
}
