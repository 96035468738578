import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../Features/languageSlice';
import { useNavigate } from "react-router-dom";
  
function Login() {

  const [url, setUrl] = useState([]);
  // Redux
  const dispatch = useDispatch(); 
 
  useEffect(() => {
    document.title = "C2C Telcomart";   

    let completeUrl = window.location.href;
    const parts = completeUrl.split("/");
    const modifiedUrl = parts.slice(0, 3).join("/");
    setUrl(modifiedUrl)
 
  }, []);   
  
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [password, setPassword] = useState();
 
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  const login = async () => {
    if (!name) {
      alert("Lütfen kullanıcı adınızı yazın.");

    } else if (!password) {
      alert("Lütfen şifrenizi yazın.");

    } else if (!validateEmail(name)) {
      alert("E-Posta formatınızı kontrol edin.");

    } else {
      let requestBody = {
        email: name,
        password: password
      };
 
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_API_LOCAL}/public/signin`,
          requestBody
        );

        if (response?.data?.result && response?.data?.result == "Success") {
          dispatch(setLanguage(response.data.data.lang));
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("userid", response.data.data.id);
          localStorage.setItem("lang", response.data.data.lang);
          navigate("/chat", { replace: true });
        } else {
          alert("Hatalı kullanıcı adı ya da şifre!");
        }
      } catch (error) {
        alert("Hatalı kullanıcı adı ya da şifre!");
      } 
    }
  }

  return (
    <> 
<div className="myBody">
<div className="login-form-container">
  <form className="login-form">
    <h2>Login</h2>
    <div className="form-group">
      <label htmlFor="email">Email:</label>
      <input
            type="email"
            className="form-control"
            id="inputEmail"
            aria-describedby="emailHelp"
            autocomplete="off"
            onChange={(e) => setName(e.target.value)}
          />
    </div>
    <div className="form-group">
      <label htmlFor="password">Password:</label>
      <input type="password" 
            className="form-control" 
            id="inputPassword" 
            autocomplete="off"
            onChange={(e) => setPassword(e.target.value)} />
    </div>
  
            <span 
              onClick={() => login()}
              style={{ cursor: "pointer", textAlign:'center' }}
              class="btn-danger"
              
              >
            Login
           </span>
           
           <div className="form-group">
           <div class="g-signin2" data-onsuccess="onSignIn"></div> 
           </div>
           <div className="form-group"> 
            <Link to="/register">Register</Link>
          </div>

  </form>
  
</div>
</div>


 </>
  );
}

export default Login;