import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import ProtectedRoute from "./Components/ProtectedRoute";

import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import Logout from "./Pages/Auth/Logout";

import Chat from "./Pages/Chat/Chat";
import ChatMobile from "./Pages/Chat/ChatMobile";

import Contacts from "./Pages/Contacts/Contacts";
import ContactsMobile from "./Pages/Contacts/ContactsMobile";

import Settings from "./Pages/Settings/Settings";
import SettingsMobile from "./Pages/Settings/SettingsMobile";

import Profile from "./Pages/Profile/Profile";
import ProfileMobile from "./Pages/Profile/ProfileMobile";

import Info from "./Pages/Info/Info";
import InfoMobile from "./Pages/Info/InfoMobile";
import SupportForm from "./Pages/SupportForm/SupportForm";
 
import Privacy from "./Pages/Privacy/Privacy";
import TwoFa from "./Pages/TwoFa/TwoFa";
import BlockedUsers from "./Pages/BlockedUsers/BlockedUsers";
import AutoMsgRemoval from "./Pages/AutoMsgRemoval/AutoMsgRemoval";
 
import AccessInformation from "./Pages/AccessInformation/AccessInformation";
import ProfileView from "./Pages/ProfileView/ProfileView";
import ProfileStatus from "./Pages/ProfileStatus/ProfileStatus";
  
import Timezone from "./Pages/Timezone/Timezone";
import DatePreferences from "./Pages/DatePreferences/DatePreferences";
import Backup from "./Pages/Backup/Backup";
 
import MyContacts from "./Pages/MyContacts/MyContacts";
import MyBlockedContacts from "./Pages/MyBlockedContacts/MyBlockedContacts";
 
function App() {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const checkMobile = () => {
      return (
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
          userAgent
        ) || window.innerWidth <= 768
      );
    };

    setIsMobile(checkMobile);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          {isMobile ? (
            <Route path="/chat" element={<ChatMobile />} />
          ) : (
            <Route path="/chat" element={<Chat />} />
          )}

          {isMobile ? (
            <Route path="/settings" element={<SettingsMobile />} />
          ) : (
            <Route path="/settings" element={<Settings />} />
          )}

          {isMobile ? (
            <Route path="/contacts" element={<ContactsMobile />} />
          ) : (
            <Route path="/contacts" element={<Contacts />} />
          )}

          {isMobile ? (
            <Route path="/profile" element={<ProfileMobile />} />
          ) : (
            <Route path="/profile" element={<Profile />} />
          )}

          {isMobile ? (
            <Route path="/info" element={<InfoMobile />} />
          ) : (
            <Route path="/info" element={<Info />} />
          )}

          <Route path="/support-form" element={<SupportForm />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/2Fa" element={<TwoFa />} />
          <Route path="/blocked-users" element={<BlockedUsers />} />
          <Route path="/auto-msg-removal" element={<AutoMsgRemoval />} />

          <Route path="/access-information" element={<AccessInformation />} />
          <Route path="/profile-view" element={<ProfileView />} />
          <Route path="/profile-status" element={<ProfileStatus />} />

          <Route path="/timezone" element={<Timezone />} />
          <Route path="/date-preferences" element={<DatePreferences />} />
          <Route path="/backup" element={<Backup />} />

          <Route path="/my-contacts" element={<MyContacts />} />
          <Route path="/my-blocked-contacts" element={<MyBlockedContacts />} />
 

          {/* <Route
          path="/main"
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          }
        /> */}
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
