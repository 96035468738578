export const timeZones = [
  { name: "UTC-12:00 United States (Baker Island)", value: "-12" },
  { name: "UTC-11:00 United States (American Samoa), Niue", value: "-11" },
  { name: "UTC-10:00 United States (Hawaii), French Polynesia", value: "-10" },
  { name: "UTC-09:00 United States (Alaska), French Polynesia", value: "-9" },
  { name: "UTC-08:00 United States (California), Canada (British Columbia)", value: "-8" },
  { name: "UTC-07:00 United States (Colorado), Canada (Alberta)", value: "-7" },
  { name: "UTC-06:00 United States (Texas), Mexico (Mexico City), Canada (Manitoba)", value: "-6" },
  { name: "UTC-05:00 United States (New York), Canada (Ontario, Quebec), Cuba", value: "-5" },
  { name: "UTC-04:00 United States (Puerto Rico), Canada (Nova Scotia), Venezuela", value: "-4" },
  { name: "UTC-03:00 Argentina, Brazil (São Paulo), Uruguay", value: "-3" },
  { name: "UTC-02:00 Brazil (Fernando de Noronha), South Georgia and the South Sandwich Islands", value: "-2" },
  { name: "UTC-01:00 Portugal (Azores), Cape Verde", value: "-1" },
  { name: "UTC+00:00 United Kingdom, Portugal, Ghana, Ivory Coast", value: "+0" },
  { name: "UTC+01:00 Germany, France, Spain, Nigeria", value: "+1" },
  { name: "UTC+02:00 Greece, Egypt, South Africa, Bulgaria", value: "+2" },
  { name: "UTC+03:00 Russia (Moscow), Turkey, Saudi Arabia, Kenya", value: "+3" },
  { name: "UTC+04:00 United Arab Emirates, Russia (Samara), Mauritius", value: "+4" },
  { name: "UTC+05:00 Pakistan, Uzbekistan, Tajikistan", value: "+5" },
  { name: "UTC+06:00 Bangladesh, Kazakhstan, Kyrgyzstan", value: "+6" },
  { name: "UTC+07:00 Thailand, Indonesia (Jakarta), Vietnam", value: "+7" },
  { name: "UTC+08:00 China, Singapore, Australia (Western Australia), Taiwan", value: "+8" },
  { name: "UTC+09:00 Japan, South Korea, Indonesia (Eastern)", value: "+9" },
  { name: "UTC+10:00 Australia (New South Wales, Victoria), Papua New Guinea", value: "+10" },
  { name: "UTC+11:00 Solomon Islands, Vanuatu", value: "+11" },
  { name: "UTC+12:00 New Zealand, Fiji", value: "+12" },
  { name: "UTC+13:00 Samoa, Tonga", value: "+13" },
  { name: "UTC+14:00 Kiribati (Line Islands)", value: "+14" }
];