import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Select, { components } from 'react-select';

import { languages } from '../../Constants/Languages.js';  
import { faqs } from '../../Constants/Faq.js';  
import { languagesAll } from '../../Constants/LanguagesAll.js';  
import { LeftMenu } from '../../Components/Menu.js'; 
import { LanguageSelection } from '../../Components/LanguageSelection.js';  
import { SupportMenu } from '../../Components/TabMenu.js'; 

const options = languagesAll;

// Custom component to render options with icons
const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <img
      src={`/images/flags/${props.data.value}.png`}
      style={{ width: 24, height: 24, marginRight: 10 }} 
    />
    {props.data.label}
  </Option>
);



function Chat() {

  const navigate = useNavigate();

  const endOfPageRef = useRef(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showLangs, setShowLangs] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [selectedOption, setSelectedOption] = useState('');
 

    const toggleLangSelector = () => {
      setShowLangs(!showLangs);
    }
  
    const changeLanguage = (newLang) => {

      try {
        const result = axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/settings/change-default-language`,
          {lang:newLang},
          {
            headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
          }
        );
      } catch (error) {}

      setLang(newLang);
      localStorage.setItem("lang",newLang);
 
    }
 
    
 
  function getLanguageNameByCode(code) {
    const language = languages.find(lang => lang.code === code);
    return language ? language.name : 'Language not found';
  }
  
  useEffect(() => {

    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);
 
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
<>
  <div className="container">
    <div className="column1">
      <div className="left-side-menu">
        <div>
        <LeftMenu selected="Info"/>
        </div>
      </div>
    </div>
    <div className="column2-settings"> 
      <div className="header">
        <b>Bilgi & Destek</b>
         
        <div className="nav-icons"  >
        <LanguageSelection />
        </div> 
      </div>  

      <SupportMenu selected="Contacts"/>
 
      <div class="form-double-container"> 
    <div className="half-width"> 




    <div className="faq-container">
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleAnswer(index)}>
              {faq.question}
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>




    </div> 
 
</div>  

    </div> 
  </div>
</>
  );
}
 

export default Chat;
