import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from "../../Components/Menu.js";
import { PrivacyMenu } from "../../Components/TabMenu.js";
import { LanguageSelection } from "../../Components/LanguageSelection.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Privacy() {
  const navigate = useNavigate();

  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [newPassAgain, setNewPassAgain] = useState();

  const validatePassword = async () => {
    if (!oldPass) {
      alert("Lütfen eski şifreninzi yazın.");
      return false;
    } else if (!newPass) {
      alert("Lütfen yeni şifrenizi yazın.");
      return false;
    } else if (!newPassAgain) {
      alert("Lütfen yeni şifrenizi tekrar yazın.");
      return false;
    } else if (newPass != newPassAgain) {
      alert("Şifre tekrarı uyuşmuyor.");
      return false;
    } else {
      return true;
    }
  };

  const changePassword = async () => {
    let validatePasswordRes = await validatePassword();

    if (validatePasswordRes) {
      let reqBody = {
        oldPass: oldPass,
        newPass: newPass,
      };

      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API_LOCAL}/private/change-password`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ` + localStorage.getItem("token"),
            },
          }
        );
        if (result?.data?.result && result?.data?.result == "Success") {
          toast.success("👌 " + "Şifreniz güncellendi.", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("👌 " + "Hata oluştu, daha sonra tekrar deneyin.", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="column1">
          <div className="left-side-menu">
            <div>
              <LeftMenu selected="Privacy" />
            </div>
          </div>
        </div>
        <div className="column2-settings">
          <div className="header">
            <b>Privacy</b>
            <div className="nav-icons">
              <LanguageSelection />
            </div>
          </div>

          <PrivacyMenu selected="Privacy" />

          <div class="form-double-container">
            <div className="half-width">
              <form className="login-form">
                <h4>Şifre Değiştirme</h4>

                <div className="form-group">
                  <label>Eski Şifre</label>
                  <input
                    type="password"
                    className="form-control"
                    autocomplete="off"
                    onChange={(e) => setOldPass(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Yeni Şifre</label>
                  <input
                    type="password"
                    className="form-control"
                    autocomplete="off"
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Yeni Şifre Tekrar</label>
                  <input
                    type="password"
                    className="form-control"
                    autocomplete="off"
                    onChange={(e) => setNewPassAgain(e.target.value)}
                  />
                </div>

                <span
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => changePassword()}
                  class="btn-danger"
                >
                  Şifreyi Güncelle
                </span>
              </form>
            </div>

            <div className="half-width">
              <form className="login-form">
                <h4>Açıklamalar</h4>
                <ul className="settingDescriptions">
                  <li>Sisteme erişim şifrenizi buradan değiştirebilirsiniz.</li>
                  <li>
                    En az 8 karakter, harf ve numaradan oluşan şifreleri tercih
                    ediniz.
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </>
  );
}

export default Privacy;
