import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom"; 
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
  
  useEffect(() => {
    document.title = "C2C Telcomart";   

    let completeUrl = window.location.href;
    const parts = completeUrl.split("/");
    const modifiedUrl = parts.slice(0, 3).join("/");
 
  }, []);   
  
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
 
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  const register = async () => {
    if (!name) {
      alert("Lütfen adınızı yazın.");

    } else if (!surname) {
        alert("Lütfen soyadınızı yazın.");

    } else if (!email) {
        alert("Lütfen e-postanızı yazın.");

    } else if (!validateEmail(email)) {
      alert("E-Posta formatınızı kontrol edin.");

    } else if (!password) {
      alert("Lütfen şifrenizi yazın.");

    } else {
      let requestBody = {
        email: email,
        name: name,
        surname: surname,
        password: password
      };
 
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_API_LOCAL}/public/signup`,
          requestBody
        );

        if (response?.data?.result && response?.data?.result == "Success") { 

            toast.success('👌 '+"Kullanıcı kaydınız yapıldı. Login olabilirsiniz.", 
            {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
          }); 

         // navigate("/login", { replace: true });
        } else {
          alert("Hata oluştu!");
        }
      } catch (error) {
        alert("Hatalı kullanıcı adı ya da şifre!");
      }


    }
  }

  return (
    <>
     
<div className="myBody">
<div className="login-form-container">
  <form className="login-form">
    <h2>Register</h2>
    <div className="form-group">
      <label htmlFor="email">İsim:</label>
      <input
            type="text"
            className="form-control"
            autocomplete="off"
            onChange={(e) => setName(e.target.value)}
          />
    </div>

    <div className="form-group">
      <label htmlFor="email">Soyisim:</label>
      <input
            type="text"
            className="form-control"
            autocomplete="off"
            onChange={(e) => setSurname(e.target.value)}
          />
    </div>

    <div className="form-group">
      <label htmlFor="email">Email:</label>
      <input
            type="email"
            className="form-control"
            autocomplete="off"
            onChange={(e) => setEmail(e.target.value)}
          />
    </div>


    <div className="form-group">
      <label htmlFor="password">Password:</label>
      <input type="password" 
            className="form-control" 
            id="inputPassword" 
            autocomplete="off"
            onChange={(e) => setPassword(e.target.value)} />
    </div>
  
 

            <span 
              onClick={() => register()}
              style={{ cursor: "pointer", textAlign:'center' }}
              class="btn-danger"
              
              >
            Register
           </span>
           <div className="form-group">
           <div class="g-signin2" data-onsuccess="onSignIn"></div> 
           </div>

           <div className="form-group"> 
            <Link to="/login">Login</Link>
          </div>

  </form>
  
</div>
</div>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />
 </>
  );
}

export default Register;