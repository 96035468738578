import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LeftMenu } from "../../Components/Menu.js";
import { LanguageSelection } from "../../Components/LanguageSelection.js";
import { timeZones } from "../../Constants/TimeZones.js";
import { SettingsMenu } from "../../Components/TabMenu.js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Chat() {
  const navigate = useNavigate();

  // State to track the selected timezone
  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZones[12].value); // Default to UTC+00:00

  // Handle the selection change
  const handleChange = (event) => {
    setSelectedTimeZone(event.target.value);
  }
 
  const updateTimeZone = async () => {
 
    let reqBody = {
      timezone:selectedTimeZone
    }

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/update-timezone`,
        reqBody,
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.result && result?.data?.result == "Success") { 

        toast.success('👌 '+"Bilgileriniz güncellendi.", 
        {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
      }); 
    } else {
      toast.error('👌 '+"Hata oluştu, daha sonra tekrar deneyin.", 
      {position: "top-right",autoClose: 2500,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"
    }); 
    }
    } catch (error) {
      console.log(error)
    }
  }

  const getProfileInformation = async () => {
 
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_LOCAL}/private/get-profile-info`,
        {},
        {
          headers: { Authorization: `Bearer ` + localStorage.getItem("token") },
        }
      );
      if (result?.data?.data) {
        console.log(result?.data?.data);
        setSelectedTimeZone(result?.data?.data?.timezone);
       
      }
    } catch (error) {
      console.log(error)
    }
    return true;
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login", { replace: true });
    }
    getProfileInformation();
  }, []);

  return (
    <>
      <div className="container">
        <div className="column1">
          <div className="left-side-menu">
            <div>
              <LeftMenu selected="Settings" />
            </div>
          </div>
        </div>
        <div className="column2-settings">
          <div className="header">
            <b>Ayarlar</b>
            <div className="nav-icons">
              <LanguageSelection selected="Settings" />
            </div>
          </div>

          <SettingsMenu selected="Timezone" />

          <div class="form-double-container">
            <div className="half-width">
              <form className="login-form">
                <h4>Zaman Dilimi Seçme</h4>

                <div className="form-group">
                  <select value={selectedTimeZone} onChange={handleChange}>
                    {timeZones.map((timezone, index) => (
                      <option key={index} value={timezone.value}>
                        {timezone.name}
                      </option>
                    ))}
                  </select>
                </div> 

           
                <span
                  style={{ cursor: "pointer", textAlign: "center" }}
                  class="btn-danger"
                  onClick={() => updateTimeZone()}
                >
                  Zaman Diliminin Güncelle
                </span>
          
              </form>
            </div>
            <div className="half-width">
              <form className="login-form">
                <h4>Açıklamalar</h4>
                <ul className="settingDescriptions">
                  <li>
                    Yaşadığınız yere uygun zaman diliminin seçerek mesaj
                    zamanını en doğru şekilde görüntüleyebilirsiniz
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<ToastContainer />

    </>
  );
}

export default Chat;
